let showTimeout = 0;

function showTooltip(e: MouseEvent) {
    let tooltip: HTMLElement = document.querySelector(".dojo-tooltip") as HTMLElement;
    if (!tooltip) {
        tooltip = document.createElement("div");
        tooltip.classList.add('dojo-tooltip');
        document.body.appendChild(tooltip);
    }
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
    const above = rect.bottom + 80 > window.innerHeight;
    showTimeout = setTimeout(() => {
        tooltip.classList.toggle('above', above);
        tooltip.style.left = rect.left + rect.width / 2 + "px";
        if (above) {
            tooltip.style.top = rect.top - 3 + "px";
        } else {
            tooltip.style.top = rect.top + rect.height + 3 + "px";
        }
        tooltip.classList.remove('hide');
        tooltip.innerHTML = (e.target as HTMLElement).getAttribute('data-tooltip') as string;
    }, 500);
}

function hideTooltip() {
    const tooltip = document.querySelector(".dojo-tooltip");
    if (tooltip) {
        clearTimeout(showTimeout);
        tooltip.classList.add('hide');
    }
}

export default {
    mounted(el, binding, vnode, prevVnode) {
        el.setAttribute("data-tooltip", binding.value);
        el.addEventListener('mouseenter', showTooltip);
        el.addEventListener('mouseleave', hideTooltip);
    },
    unmounted(el) {
        el.removeEventListener('mouseenter', showTooltip);
        el.removeEventListener('mouseleave', hideTooltip);
    }
}