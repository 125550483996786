
import PseudoZone from './PseudoZone.vue'
import GlyphesZone from './GlyphesZone.vue'
import CurrentProdigeZone from './CurrentProdigeZone.vue'
import WaitingProdigeZone from './WaitingProdigesZone.vue'
import {defineComponent} from "@vue/runtime-core";
import {isZone} from "@/types/GameTypes";

export default defineComponent({
    props: {
        zone_type: {
            type: String,
            required: true,
            validator: isZone
        },
    },
    components: {
        PseudoZone,
        GlyphesZone,
        CurrentProdigeZone,
        WaitingProdigeZone
    }
})
