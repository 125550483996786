

import {defineComponent, PropType} from "@vue/runtime-core";
import {LobbyPlayerData} from "@/stores/global";
import {useNamespacedState} from "vuex-composition-helpers";
import {LobbyStateData} from "@/stores/lobby";

export default defineComponent({
  setup() {
    let lobbyState = useNamespacedState<LobbyStateData>('lobby', ['selected_opponent']);
    return {
      lobbyState
    }
  },
  computed: {
    isSelected: function (): boolean {
      return this.opponent.pseudo === this.lobbyState.selected_opponent.value
    },
    selected_opponent: function (): string {
      return this.lobbyState.selected_opponent.value
    }
  },
  props: {
    opponent: {
      type: Object as PropType<LobbyPlayerData>,
      required: true
    }
  },
  methods: {
    select() {
      this.$store.commit('lobby/setSelectedOpponent', this.opponent.pseudo);
    }
  }
})
