import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "maitrise-choice"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoundedButton = _resolveComponent("RoundedButton")!

  return (_ctx.showMaitrise)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_RoundedButton, {
          class: "rounded-button--center",
          "click-event": _ctx.activate_maitrise
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Maîtrise de " + _toDisplayString(_ctx.played_player_prodige.name), 1)
          ]),
          _: 1
        }, 8, ["click-event"]),
        _createVNode(_component_RoundedButton, {
          class: "rounded-button--center",
          "click-event": _ctx.activate_voie
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Activer la voie " + _toDisplayString(_ctx.maitrise_choosing), 1)
          ]),
          _: 1
        }, 8, ["click-event"])
      ]))
    : _createCommentVNode("", true)
}