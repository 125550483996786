

import {defineComponent, PropType} from "@vue/runtime-core";
import {LobbyGameToSpectateData} from "@/stores/global";
import {useNamespacedState} from "vuex-composition-helpers";
import {LobbyStateData} from "@/stores/lobby";

export default defineComponent({
  setup() {
    let lobbyState = useNamespacedState<LobbyStateData>('lobby', ['selected_game_to_spectate']);
    return {
      lobbyState
    }
  },
  computed: {
    isSelected: function (): boolean {
      return this.game.game_id === this.lobbyState.selected_game_to_spectate.value
    },
  },
  props: {
    game: {
      type: Object as PropType<LobbyGameToSpectateData>,
      required: true
    }
  },
  methods: {
    select() {
      this.$store.commit('lobby/setSelectedGameToSpectate', this.game.game_id);
    }
  }
})
