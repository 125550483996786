import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc1a48f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "draft" }
const _hoisted_2 = { class: "prodiges-to-draft" }
const _hoisted_3 = {
  key: 1,
  class: "slot slot--empty"
}
const _hoisted_4 = { class: "battlefield" }
const _hoisted_5 = { class: "draft-zone" }
const _hoisted_6 = { class: "selected-prodiges" }
const _hoisted_7 = { class: "draft-zone draft-zone--dark" }
const _hoisted_8 = { class: "selected-prodiges" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpectatorScreen = _resolveComponent("SpectatorScreen")!
  const _component_MenuBar = _resolveComponent("MenuBar")!
  const _component_DisplayedSection = _resolveComponent("DisplayedSection")!
  const _component_PauseScreen = _resolveComponent("PauseScreen")!
  const _component_ConfirmExitScreen = _resolveComponent("ConfirmExitScreen")!
  const _component_ExitScreen = _resolveComponent("ExitScreen")!
  const _component_Preload = _resolveComponent("Preload")!
  const _component_Prodige = _resolveComponent("Prodige")!
  const _component_PseudoZone = _resolveComponent("PseudoZone")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.spectator_mode)
      ? (_openBlock(), _createBlock(_component_SpectatorScreen, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_MenuBar),
    _createVNode(_component_DisplayedSection),
    (_ctx.paused)
      ? (_openBlock(), _createBlock(_component_PauseScreen, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.confirm_exit)
      ? (_openBlock(), _createBlock(_component_ConfirmExitScreen, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.opponent_left_game && !_ctx.confirm_exit)
      ? (_openBlock(), _createBlock(_component_ExitScreen, { key: 3 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Preload),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.available_prodiges, (prodige, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          (prodige !== null)
            ? (_openBlock(), _createBlock(_component_Prodige, {
                key: 0,
                class: "slot",
                data: prodige,
                interactive: _ctx.me.my_turn
              }, null, 8, ["data", "interactive"]))
            : _createCommentVNode("", true),
          (prodige === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_PseudoZone, { zone_type: "opp_zone" }),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.opp_prodiges, (prodige) => {
            return (_openBlock(), _createBlock(_component_Prodige, {
              class: "slot",
              key: prodige.name,
              interactive: false,
              data: prodige
            }, null, 8, ["data"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.missing_opp_prodiges, (missing_opp_prodige) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "slot slot--empty",
              key: missing_opp_prodige
            }))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_PseudoZone, { zone_type: "player_zone" }),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.player_prodiges, (prodige) => {
            return (_openBlock(), _createBlock(_component_Prodige, {
              key: prodige.name,
              interactive: false,
              data: prodige
            }, null, 8, ["data"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.missing_player_prodiges, (missing_player_prodige) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "slot slot--empty",
              key: missing_player_prodige
            }))
          }), 128))
        ])
      ])
    ])
  ]))
}