
import {defineComponent} from "@vue/runtime-core";
import socket from "@/utils/socket";

export default defineComponent({
    methods: {
        cancelConfirmation: function() {
            this.$store.commit('game/set_confirm_exit', false)
        },
        goToHome: function () {
            this.$store.commit('game/set_confirm_exit', true)
            socket.game.leaveGame();
            window.location.href = process.env.VUE_APP_DOJO_URL
        }
    }
});

