import socketInstance from '@/socket-instance';

const socket = {
    all: {
        getReconnectionData: () => {
            return socketInstance.emit('get_reconnection_data');
        }
    },
    login: {
        join: (opponent_pseudo: string) => {
            return socketInstance.emit('join', opponent_pseudo);
        },
        acceptMatch: () => {
            return socketInstance.emit('accept_match');
        },
        cancelMatch: () => {
            return socketInstance.emit('cancel_match');
        },
        sendPseudo: (pseudo: string) => {
            return socketInstance.emit('send_pseudo', pseudo);
        },
        askForOppList: () => {
            return socketInstance.emit('ask_for_opp_list');
        },
        spectate: (game_id: string) => {
            return socketInstance.emit('spectate_game', game_id);
        },
        askForGamesToSpectate() {
            return socketInstance.emit('ask_for_games_to_spectate');
        }
    },
    game: {
        sendMessage: (message: string) => {
            return socketInstance.emit('chat_message', message);
        },
        playingGlypheVoie: (glyphe_value: number, voie: string) => {
            return socketInstance.emit('playing_glyphe_voie', glyphe_value, voie);
        },
        switchingGlyphesVoies: (voie_start: string, voie_end: string) => {
            return socketInstance.emit('switching_glyphes_voies', voie_start, voie_end);
        },
        getAvailableProdiges: () => {
            return socketInstance.emit('get_available_prodiges');
        },
        selectProdige(prodige_name: string) {
            return socketInstance.emit('selecting_prodige', prodige_name);
        },
        playProdige(prodige_name: string) {
            return socketInstance.emit('playing_prodige', prodige_name);
        },
        prodigeClickEvent: (event: string, prodige_name: string) => {
            return socketInstance.emit(event, prodige_name);
        },
        addGlypheTarget: (glyphe_value: number) => {
            return socketInstance.emit('add_glyphe_target', glyphe_value);
        },
        removeGlypheTarget: (glyphe_value: number) => {
            return socketInstance.emit('remove_glyphe_target', glyphe_value)
        },
        addGlypheVoieTarget: (voie: string) => {
            return socketInstance.emit('add_glyphe_voie_target', voie);
        },
        removeGlypheVoieTarget: (voie: string) => {
            return socketInstance.emit('remove_glyphe_voie_target', voie);
        },
        validSelection: () => {
            return socketInstance.emit('valid_selection');
        },
        clickVoie: (voie: string) => {
            return socketInstance.emit('click_voie', voie);
        },
        applyMaitrise: () => {
            return socketInstance.emit('click_maitrise');
        },
        leaveGame: () => {
            return socketInstance.emit('leave_game');
        },
        checkMaitriseValid: () => {
            return socketInstance.emit('check_maitrise_valid');
        },
    }
}

export default socket