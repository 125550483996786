import VueSocketIOExt from 'vue-socket.io-extended';
import {App, createApp} from 'vue';
import { createStore } from 'vuex';
import AppDojoOnline from './App.vue';
import router from './router';
import socketInstance from './socket-instance'
// Vuex store creation
import globalStore, {RootStateData} from './stores/global';
const store = createStore<RootStateData>(globalStore);

// App creation
const app: App = createApp(AppDojoOnline);

app.use(router);
app.use(VueSocketIOExt, socketInstance, { store });
app.use(store)

app.mount('#app');
