<template>
<div class="screen"></div>
</template>

<script>
export default {
  name: "SpectatorScreen"
}
</script>

<style scoped lang="scss">
.screen {
  position: absolute;
  left: var(--sizeMenuBar);
  z-index: 10;
  width: var(--sizeSpectatorScreen);
  height: 100%;
}
</style>