import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27f61e7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  class: "voie-jeton__vfx",
  ref: "voie_vfx"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["voie-jeton", [
            'voie-jeton--' + _ctx.voie_state,
            { 'voie-jeton--interactive': _ctx.interactive },
        ]])
  }, [
    _withDirectives(_createElementVNode("img", {
      class: _normalizeClass(["voie-jeton__img", ['voie-jeton__img--' + _ctx.voie_state]]),
      src: _ctx.image_url,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectVoie && _ctx.selectVoie(...args))),
      draggable: "false"
    }, null, 10, _hoisted_1), [
      [_directive_tooltip, this.description]
    ]),
    _createElementVNode("div", _hoisted_2, null, 512)
  ], 2))
}