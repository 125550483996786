
import {defineComponent} from "@vue/runtime-core";
import ActionHistory from "@/components/menu_components/ActionHistory.vue";
import Chat from "@/components/menu_components/Chat.vue";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";

export default defineComponent({
    components: {ActionHistory, Chat},
    setup() {
        let {menu_data} = mapUnwrapStates(
            useNamespacedState<GameStateData>("game", ["menu_data"])
        );
        return {
            menu_data,
        };
    },
    computed: {
        state: function (): string {
            return this.menu_data.menu_bar_opened ? "opened" : "closed";
        },
    },
    methods: {
        toggle() {
            this.$store.commit("game/toggle_menu_bar_opened");
        },
        askConfirmExit() {
            this.$store.commit('game/set_confirm_exit', true)
        },
        displaySection(section) {
            this.$store.commit("game/menu_bar_display_section", section)
        },
        toggleFullScreen() {
            if (document.fullscreenElement != null) {
                document.exitFullscreen();
            } else {
                document.documentElement.requestFullscreen();
            }
        },
    },
});
