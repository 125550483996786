import {Store} from "vuex";
import {RootStateData} from "@/stores/global";

let loaded: number = 0
let total_images_to_load: number = 0

export function getProgressFunction(context: Store<RootStateData>) {
    return function () {
        loaded++;
        if (loaded == total_images_to_load) {
            context.commit('game/set_all_images_loaded')
        }
    }
}

export function set_total_images_to_load(n: number) {
    total_images_to_load = n
}


function get_all_image_paths_from_request(request) {
    const paths: string[] = []
    request.keys().forEach((key) => (paths.push(request(key))))
    return paths
}

export function get_list_images_to_load(list_prodiges: string[]) {
    let list_images: string[] = []
    list_images = list_images.concat(_get_list_basic_images_to_load())
    list_images = list_images.concat(_get_list_prodige_images_to_load(list_prodiges))
    return list_images
}

function _get_list_prodige_images_to_load(list_prodiges: string[]) {
    const list_images: string[] = []
    for (let i = 0; i < list_prodiges.length; i++) {
        const name: string = list_prodiges[i]
        list_images.push(require(`/src/assets/prodiges/cercle/` + name + `_Cercle.png`))
        list_images.push(require(`/src/assets/prodiges/` + name + `.png`))
    }
    return list_images
}

function _get_list_basic_images_to_load() {
    let list_images: string[] = []

    // Diverse images
    const images_root = get_all_image_paths_from_request(require.context(
        '/src/assets/',
        true,
        /^.*\.png$/
    ))

    // Card related images
    const images_cartes = get_all_image_paths_from_request(require.context(
        '/src/assets/cartes/',
        false,
        /^.*\.png$/
    ))

    // Glyphes related images
    const images_glyphes = get_all_image_paths_from_request(require.context(
        '/src/assets/glyphes/',
        false,
        /^.*\.png$/
    ))

    // Statistiques related images
    const images_statistiques = get_all_image_paths_from_request(require.context(
        '/src/assets/statistiques/',
        false,
        /^.*\.png$/
    ))

    list_images = list_images.concat(images_root, images_cartes, images_glyphes, images_statistiques)
    return list_images
}

export function load_images(list_images: string[], store: Store<RootStateData>) {
    const n_images: number = list_images.length
    set_total_images_to_load(n_images)
    const progress = getProgressFunction(store)

    for (let i = 0; i < n_images; i++) {
        const tempImage = new Image();
        tempImage.addEventListener("load", progress, true);
        tempImage.src = list_images[i]
    }
}

export function load_loading_circle() {
    const tempImage = new Image();
    tempImage.addEventListener("load", () => null, true);
    tempImage.src = require('/src/assets/icons/loading.svg')
}