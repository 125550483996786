

import {defineComponent} from "@vue/runtime-core";
import {useNamespacedState} from "vuex-composition-helpers";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {GameStateData} from "@/stores/game";
import {isElement} from "@/types/GameTypes";
import TargetGlyphe from "@/components/game_components/TargetGlyphe.vue";
import socket from "@/utils/socket";

export default defineComponent({
  components: {TargetGlyphe},
  setup() {
    let {
      required_actions,
      current_selection,
      me
    } = mapUnwrapStates(useNamespacedState<GameStateData>("game", ["required_actions", "current_selection", "me"]))

    return {
      required_actions,
      current_selection,
      me
    }
  },
  methods: {
    validateAction() {
      socket.game.validSelection();
    }
  },
  computed: {
    already_selected_values(): Array<number> {
      let glyphes: Array<number> = []
      if (this.required_actions.me?.target_zone === 'voie') {
        for (let voie of this.current_selection) {
          let value: number = this.me.voies[voie]
          glyphes.push(value)
        }
      } else if (this.required_actions.me?.target_zone === 'hand') {
        for (let glyphe_value in this.current_selection) {
          if (typeof glyphe_value === "number") {
            glyphes.push(glyphe_value)
          }
        }
      }
      return glyphes
    },
    empty_slots(): number {
      if (this.required_actions.me) {
        let target: number = this.required_actions.me?.target_number
        let already: number = this.current_selection.length
        return target - already
      } else {
        return 0
      }
    },
    player_action(): boolean {
      return !!this.required_actions.me;
    },
    opponent_action(): boolean {
      return !!this.required_actions.opponent;
    },
    messageCibles(): string[] {
      return this.current_selection.map((selectedValue) => {
        if (this.required_actions.me?.target_zone === 'hand' && typeof selectedValue === 'number') {
          return `Glyphe de valeur ${selectedValue}`;
        } else if (this.required_actions.me?.target_zone === 'voie' && isElement(selectedValue as string)) {
          return `Glyphe voie ${selectedValue}`;
        } else {
          return "invalid object " + selectedValue;
        }
      });
    }
  }
});
