
import GlypheVoie from './GlypheVoie.vue'
import VoieImg from './VoieImg.vue'
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
    props: {
        element: String
    },
    components: {
        GlypheVoie,
        VoieImg
    }

})
