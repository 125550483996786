import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-576bd199"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "prodige-stat__operation"
}
const _hoisted_2 = { class: "prodige-stat__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["prodige-stat", ['prodige-stat--' + _ctx.type, 'prodige-stat--' + _ctx.element,
         'prodige-stat--size-' + _ctx.display,
         {
            'prodige-stat--boosted': _ctx.isBoosted,
            'prodige-stat--nerfed': _ctx.isNerfed
        }]])
  }, [
    (_ctx.operation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.operation), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1)
  ], 2))
}