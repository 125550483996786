
import RoundedButton from "@/components/shared/RoundedButton.vue";
import {defineComponent} from "@vue/runtime-core";
import {useNamespacedGetters, useNamespacedState} from "vuex-composition-helpers";
import {mapUnwrapGetters, mapUnwrapStates} from "../../types/vuex-composition-helpers_unwrap";
import {GameStateData, GameStateGetters} from "../../stores/game";
import socket from "@/utils/socket";
import {Elements} from "@/types/GameTypes";
export default defineComponent({
    components: {RoundedButton},
    setup() {
        let {maitrise_choosing, me, game_state} = mapUnwrapStates(useNamespacedState<GameStateData>("game", ["maitrise_choosing", "game_state", "me"]));
        let {played_player_prodige} = mapUnwrapGetters(useNamespacedGetters<GameStateGetters>("game",["played_player_prodige"]));

        return {
            me,
            game_state,
            maitrise_choosing,
            played_player_prodige
        }
    },
    computed: {
        showMaitrise(): boolean {
            return !!this.maitrise_choosing && !!this.game_state.targets_available;
        }
    },
    methods: {
        activate_maitrise(): void {
            socket.game.applyMaitrise();
            this.$store.commit("game/set_maitrise_choosing", null);
        },
        activate_voie(): void {
            socket.game.clickVoie(this.maitrise_choosing as Elements);
            this.$store.commit("game/set_maitrise_choosing", null);
        }
    }
})
