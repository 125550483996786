
import {defineComponent, PropType} from "@vue/runtime-core";
import {isElement, isZone, Zone} from "@/types/GameTypes";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";
import {startDraggingGlyphe} from "@/utils/glypheDragDrop";
import socket from "@/utils/socket";

export default defineComponent({
  props: {
    zone_type: {
      type: String as PropType<Zone>,
      required: true,
      validator: isZone
    },
    value: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true
    }
  },
  setup() {
    let {
      selected_glyphe,
      game_state,
      required_actions
    } = mapUnwrapStates(useNamespacedState<GameStateData>("game", ["selected_glyphe", "game_state", "required_actions"]));

    return {
      selected_glyphe,
      game_state,
      required_actions
    }
  },
  computed: {
    bgImage: function (): { backgroundImage: string } {
      return {
        backgroundImage: 'url(' + require(`../../assets/glyphes/Glyphe_${this.value}.png`) + ')'
      }
    },
    isPlayingGlyphe(): boolean {
      return this.game_state.phase_name === 'PlayingGlyphes' && this.zone_type === 'player_zone';
    },
    isSelectingRequiredAction(): boolean {
      return ['RoundResolution', "PlayersApplyTalent"].includes(this.game_state.phase_name) && this.zone_type === 'player_zone' && !!this.required_actions.me && this.required_actions.me.target_zone === 'hand';
    },
    isActive: function (): boolean {
      return this.count > 0 && (this.isPlayingGlyphe || this.isSelectingRequiredAction);
    }
  },
  methods: {
    selectGlyphe: function (): void {
      if (this.isPlayingGlyphe) {
        this.$store.commit("game/set_selected_glyphe", this.value);

        startDraggingGlyphe({
          targetSelector: "play-glyphe",
          glypheValue: this.value,
          failAction: () => {
            this.$store.commit('game/set_selected_glyphe', -1);
          },
          successAction: (target: HTMLElement) => {
            let data = target.getAttribute('data-element');
            if (data && isElement(data)) {
              socket.game.playingGlypheVoie(this.selected_glyphe, data);
              this.$store.commit('game/set_selected_glyphe', -1);
            }
          }
        });
      }
    },
    addGlypheToSelection(e: MouseEvent): void {
      if (e.button === 0) { //left click
        socket.game.addGlypheTarget(this.value);
      }
    },
  }
})
