import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "rounded-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickEvent && _ctx.clickEvent(...args)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}