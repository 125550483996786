
import {defineComponent} from "@vue/runtime-core";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import socket from "@/utils/socket";

export default defineComponent({
    setup() {
        let {chat_log} = mapUnwrapStates(useNamespacedState<GameStateData>("game", ["chat_log"]));
        return {
            chat_log
        }
    },
    data(): { chat_message: string } {
        return {
            chat_message: ''
        }
    },
    methods: {
        sendMessage() {
            socket.game.sendMessage(this.chat_message);
            this.chat_message = '';
        }
    }
});
