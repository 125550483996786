
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";
import {defineComponent, PropType} from "@vue/runtime-core";
import {Zone} from "@/types/GameTypes";
import {PlayerHand} from "@/types/Player";
import GlypheCount from "@/components/game_components/GlypheCount.vue";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";

export default defineComponent({
    props: {
        'zone_type': {
            type: String as PropType<Zone>,
            required: true
        },
    },
    components: {
        GlypheCount
    },
    setup() {
        let {me, opponent} = mapUnwrapStates(useNamespacedState<GameStateData>("game", ["me", "opponent"]));
        return {
            me,
            opponent
        }
    },
    computed: {
        glyphes(): PlayerHand {
            return this.zone_type === "player_zone" ? this.me.hand : this.opponent.hand
        }
    },
    methods: {}
})
