import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-361eec32"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "vfx-hit",
  ref: "vfx_charge"
}
const _hoisted_2 = {
  key: 1,
  class: "missing-prodige"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prodige = _resolveComponent("Prodige")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.zone_type, "current_prodige_zone"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["debugBattleButton", { active: _ctx.prodige_hitting != null }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.launchDebugBattle()))
    }, " Debug Battle ! ", 2),
    _createElementVNode("div", {
      class: _normalizeClass(["vfx-hit", { reversed: _ctx.zone_type === 'player_zone' }]),
      ref: "vfx_hit"
    }, null, 2),
    _createElementVNode("div", _hoisted_1, null, 512),
    (_ctx.played_prodige != null)
      ? (_openBlock(), _createBlock(_component_Prodige, {
          key: 0,
          class: _normalizeClass(["current_prodige", {
                hitting:
                    (_ctx.zone_type === 'player_zone') === _ctx.prodige_hitting?.isMine,
                'is-hit':
                    (_ctx.zone_type !== 'player_zone') === _ctx.prodige_hitting?.isMine,
            }]),
          display: "big",
          interactive: false,
          data: _ctx.played_prodige
        }, null, 8, ["data", "class"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2))
  ], 2))
}