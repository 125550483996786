import {Ref, UnwrapRef} from "@vue/runtime-core";
import {RefTypes} from "vuex-composition-helpers/dist/types/util";

export type  UnwrappedRefTypes<Type> = {
    [Key in keyof Type] : Type[Key]
}

export function mapUnwrapStates<Type>(refTypes: RefTypes<Type>): UnwrappedRefTypes<Type> {
    return refTypes as unknown as UnwrappedRefTypes<Type>;
}

type InferGetterType<T> =
    T extends (...args: any) => any
        ? ReturnType<T>
        : any;

export type UnwrappedGetterTypes<O> = {
    readonly [K in keyof O]: O[K];
};

export function mapUnwrapGetters<Type>(refGetters: RefTypes<Type>): UnwrappedGetterTypes<Type> {
    return refGetters as unknown as UnwrappedGetterTypes<Type>;
}