import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-561672f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "drag-end-action", "data-element"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["glyphe-voie", {
            'glyphe-voie--interactive': _ctx.interactive,
            'glyphe-voie--selected':
                _ctx.selected_voie === _ctx.element ||
                _ctx.current_selection.includes(_ctx.element),
            'glyphe-voie--opponent': _ctx.zone_type === 'opp_zone',
            'glyphe-voie--regard': _ctx.isVoieRegard,
            'glyphe-voie--regard-empty': _ctx.isVoieRegard && _ctx.glypheValue === -1,
            'glyphe-voie--filled': _ctx.backgroundImage !== 'none',
        }]),
    id: 'glyphe-slot_' + _ctx.zone_type + '_' + _ctx.element,
    style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage }),
    "drag-end-action": _ctx.interactive ? 'play-glyphe' : '',
    onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startRemoveGlyphe && _ctx.startRemoveGlyphe(...args))),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectGlyphe && _ctx.selectGlyphe(...args))),
    onContextmenu: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeGlyphe && _ctx.removeGlyphe(...args)), ["prevent"])),
    "data-element": _ctx.element
  }, null, 46, _hoisted_1))
}