
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
    props: {
        text: {
            type: String
        },
        clickEvent: {
            type: Function,
            required: true
        }
    }
})
