

import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
    props: {
        type: {
            type: String,
            required: true,
            validator: (prop: string) => ['power', 'damage', 'pv'].includes(prop)
        },
        display: {
            type: String,
            default: "small"
        },
        value: {
            type: Number,
        },
        baseValue: {
            type: Number,
            required: true
        },
        element: {
            type: String,
            validator: (prop: string) => ['air', 'eau', 'terre', 'feu'].includes(prop)
        },
        operation: {
            type: String,
            validator: (prop: string) => ['+', '-'].includes(prop)
        }
    },
    computed: {
        isBoosted(): boolean {
            return this.value != null && this.value > this.baseValue;
        },
        isNerfed(): boolean {
            return this.value != null && this.value < this.baseValue;
        }

    }
});
