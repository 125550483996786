import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7925e812"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "redirect-home-screen__content" }
const _hoisted_2 = { class: "redirect-home-screen__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'redirect-home-screen--show': _ctx.paused }, "redirect-home-screen"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.opponent.pseudo) + " a quitté la partie", 1),
      _createElementVNode("div", {
        class: "redirect-home-screen__button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
      }, "Retour à l'accueil")
    ])
  ], 2))
}