import {createLogger, GetterTree, ModuleTree, StoreOptions} from "vuex";
import lobby, {lobbyGetters, lobbyMutations} from "@/stores/lobby";
import game, {gameGetters, GameStateData, gameStateMutations} from "@/stores/game";
import socket from "@/utils/socket";

export type GameStates = "PlayersChoosingProdiges" | "PlayersPlayingProdiges" |"PlayingGlyphes" | "RoundResolution" | "CleaningPhase" | "Idle" | "PlayersApplyTalent";

export interface LobbyPlayerData {
    pseudo: string,
    status: "available" | "receive_match_offer" | "send_match_offer" | "in_game",
    game_id: string
}

export interface LobbyGameToSpectateData {
    pseudo1: string,
    pseudo2: string,
    game_id: string,
}

export interface RootStateData {
    player_pseudo: string,
}

const state : RootStateData = {
    player_pseudo: '',
};

const getters: GetterTree<RootStateData, any> = {
};

const mutations = {
    set_player_pseudo(state: RootStateData, pseudo: string) {
        state.player_pseudo = pseudo;
    },
};

const actions = {
    socket_joinAnswer(state, answer) {
        const pseudo = answer[0]
        const success = answer[1]
        if (success) {
            console.log('join ' + pseudo + ' : success')
        } else {
            console.log('join ' + pseudo + ' : fail')
        }
    },
    socket_pseudoValid(state, pseudo) {
        state.commit('set_player_pseudo', pseudo)
        socket.login.askForOppList()
        socket.login.askForGamesToSpectate()
    }
};


const modules = {
    lobby: lobby,
    game: game
}

const root: StoreOptions<RootStateData> = {
    state,
    getters,
    actions,
    mutations,
    modules,
    plugins: [createLogger()]
};

const lobbyState = root.modules?.lobby
export default root;

export type StoreNames = keyof typeof modules;

export type GameStoreGetters = `game/${keyof  typeof gameGetters}`;
export type LobbyStoreGetters = `lobby/${keyof  typeof lobbyGetters}`;
export type StoreGetters = `${GameStoreGetters | LobbyStoreGetters | keyof typeof getters}`;
export type GameStoreMutations = `game/${keyof  typeof gameStateMutations}`;
export type LobbyStoreMutations = `lobby/${keyof  typeof lobbyMutations}`;
export type StoreMutations = `${GameStoreMutations | LobbyStoreMutations | keyof typeof mutations}`;
