
import {defineComponent} from "@vue/runtime-core";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";
import socket from "@/utils/socket";

export default defineComponent({
    setup() {
        let {opponent} = mapUnwrapStates(useNamespacedState<GameStateData>("game", ["opponent"])
        );
        return {opponent,};
    },
    methods: {
        goToHome: function () {
            socket.game.leaveGame();
            window.location.href = process.env.VUE_APP_DOJO_URL
        }
    }
});

