
import {defineComponent} from "@vue/runtime-core";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";
import socket from "@/utils/socket";

export default defineComponent({
    data() {
        return {
            end: 0,
            minutes: 5,
            seconds: 0
        }
    },
    setup() {
        let {opponent, paused, pause_interval} = mapUnwrapStates(
            useNamespacedState<GameStateData>("game", [
                "opponent",
                "paused",
                "pause_interval"
            ])
        );

        return {
            opponent,
            paused,
            pause_interval,
        };
    },
    mounted() {
        if (this.pause_interval !== 0) {
            clearInterval(this.pause_interval)
        }
        this.end = Date.now() + 300 * 1000;
        this.timerCount(this.end);
        const pause_interval = setInterval(() => {
            this.timerCount(this.end);
        }, 1000);
        this.$store.commit('game/set_pause_interval', pause_interval)
    },
    computed: {
        formatedSeconds(): string {
            let str: string = '' + this.seconds
            if (this.seconds < 10) {
                str = '0' + this.seconds
            }
            return str
        }
    },
    methods: {
        timerCount: function (end) {
            const now = Date.now();
            const remaining = end - now;

            if (remaining < 0) {
                clearInterval(this.pause_interval);
                setTimeout(this.redirect, 1000)
                document.getElementsByClassName('pause-screen__text')[0].innerHTML = "Retour à l'accueil..."
                return;
            } else {
                this.minutes = Math.floor(remaining / (60 * 1000));
                this.seconds = Math.floor((remaining - 60 * 1000 * this.minutes) / 1000);
            }
        },
        redirect: function () {
            socket.game.leaveGame();
            window.location.href = process.env.VUE_APP_DOJO_URL
        }
    }
});

