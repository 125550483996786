import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.bgImage),
    class: _normalizeClass(["glyphe-count", 
         {
          'glyphe-count--selected': _ctx.selected_glyphe === _ctx.value && _ctx.zone_type === 'player_zone',
          'glyphe-count--active': _ctx.isActive,
          'glyphe-count--invisible': _ctx.count === 0
         }]),
    onMousedown: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.selectGlyphe && _ctx.selectGlyphe(...args)), ["prevent"])),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addGlypheToSelection && _ctx.addGlypheToSelection(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.zone_type, "counter"])
    }, _toDisplayString(_ctx.count), 3)
  ], 38))
}