import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c5a1a9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action-history" }
const _hoisted_2 = { class: "action-history__wrapper" }
const _hoisted_3 = { class: "action-history__timestamp" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formattedHistory, (message) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "action-history__line",
          key: message.timestamp
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formattedTimestamp(message)), 1),
          _createElementVNode("div", {
            class: "action-history__message",
            innerHTML: message.formattedMessage
          }, null, 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}