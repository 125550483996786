
import Prodige from './prodige/Prodige.vue';
import MenuBar from "@/components/menu_components/MenuBar.vue";
import Preload from "@/components/Preload.vue"
import SpectatorScreen from "@/components/shared/SpectatorScreen.vue"
import DisplayedSection from "@/components/shared/DisplayedSection.vue";
import {defineComponent} from "@vue/runtime-core";
import PseudoZone from "@/components/game_components/PseudoZone.vue";
import {useNamespacedGetters, useNamespacedState} from "vuex-composition-helpers";
import {GameStateData, GameStateGetters} from "@/stores/game";
import {mapUnwrapGetters, mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import socket from "@/utils/socket";
import PauseScreen from "@/components/shared/PauseScreen.vue";
import ConfirmExitScreen from "@/components/shared/ConfirmExitScreen.vue";
import ExitScreen from "@/components/shared/ExitScreen.vue";


export default defineComponent({
    props: ['roomId'],
    components: {
        PseudoZone,
        MenuBar,
        Prodige,
        Preload,
        SpectatorScreen,
        DisplayedSection,
        PauseScreen,
        ConfirmExitScreen,
        ExitScreen
    },
    setup() {
        const {
            spectator_mode,
            available_prodiges,
            roomId,
            me,
            opponent,
            paused,
            confirm_exit,
            opponent_left_game
        } = mapUnwrapStates(useNamespacedState<GameStateData>(
            'game',
            [
                'roomId',
                'available_prodiges',
                'me',
                'spectator_mode',
                'opponent',
                'paused',
                'confirm_exit',
                'opponent_left_game'
            ]
        ));
        const {
            player_prodiges,
            opp_prodiges
        } = mapUnwrapGetters(useNamespacedGetters<GameStateGetters>(
            'game',
            ['player_prodiges', 'opp_prodiges']
        ));

        return {
            spectator_mode,
            room_id: roomId,
            me,
            opponent,
            available_prodiges,
            player_prodiges,
            opp_prodiges,
            paused,
            confirm_exit,
            opponent_left_game
        }
    },
    watch: {
        me: {
            handler() {
                document.title = `${this.me.my_turn ? '⏰' : ''} Dojo - Draft vs ${this.opponent.pseudo}`;
            },
            immediate: true,
            deep: true
        }
    },
    created: function (): void {
        if (this.room_id === '') {
            this.$router.push('/');
        }
        socket.game.getAvailableProdiges();

    },
    sockets: {
        start_game() {
            this.$router.push({path: `/play/${this.roomId}`})
        }
    },
    computed: {
        missing_player_prodiges: function (): string[] {
            let missing: string[] = [];
            for (let i = this.player_prodiges.length; i < 4; i++) {
                missing.push(i.toString());
            }
            return missing;
        },
        missing_opp_prodiges: function (): string[] {
            let missing: string[] = [];
            for (let i = this.opp_prodiges.length; i < 4; i++) {
                missing.push("opp_" + i.toString());
            }
            return missing;

        },
    }
})
