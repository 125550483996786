import {GetterTree} from "vuex";
import {GameStates, RootStateData} from "@/stores/global";
import {ProdigeData} from "@/types/Prodiges";
import {PlayerData, PlayerHand} from "@/types/Player";
import {Elements, VoiesElements} from "@/types/GameTypes";

export type ResolutionTargets = Elements | "maitrise";

export interface GameState {
    available_prodiges?: ProdigeData[];
    phase_name: GameStates,
    subphase?: string,
    targets_available?: AvailableTargets,
    winners?: Array<string>
}

export interface RouteData {
    route: string,
    room_id: string
}

export interface InteractingWithProdigeData {
    pseudo: string,
    prodigeName: string
}

export interface CapaciteData {
    'timestamp': number,
    'effect': string,
    'text': string,
    'text_without_modificator': string,
    'description': string,
    'condition_validated': boolean,
    'stopped': boolean,
    'source': { 'type': 'prodige_damage' | 'maitrise' | 'talent' | 'voie', 'entity': string }
    'target_type': 'player' | 'prodige',
    'target_player': 'me' | 'opponent',
    'owner': string,
    'modificator': string,
    'condition': string,
    'contrecoup': boolean,
    'base_value': number,
    'value': number,
    'final_value': number
}

export interface HandSocketData {
    pseudo: string,
    hand: PlayerHand
}

export interface EndGameData {
    winner: string,
    equal: boolean
}

export interface AvailableTargets {
    [pseudo: string]: Array<ResolutionTargets>
}

export interface ConditionActivations {
    [pseudo: string]: {
        [prodigeName: string]: {
            talent: boolean,
            maitrise: boolean
        }
    }
}

export interface TurnData {
    [pseudoPlayer: string]: boolean
}

export interface RegardData {
    pseudo: string,
    voie: Elements | null
}

export interface GameData {
    game_id: string;
    opponent: PlayerData;
    me: PlayerData;
    game_state: GameState;
    voies_data: VoiesData;
    required_actions: { me: CapaciteRequirements | null, opponent: CapaciteRequirements | null };
}

export interface VoiesData {
    air?: CapaciteData,
    eau?: CapaciteData,
    terre?: CapaciteData,
    feu?: CapaciteData
}

export interface MenuData {
    menu_bar_opened: boolean;
    new_chat_message: boolean;
}

export interface GameStateData {
    opponent_left_game: boolean,
    confirm_exit: boolean,
    paused: boolean,
    pause_interval: number,
    menu_data: MenuData,
    spectator_mode: boolean,
    all_images_loaded: boolean,
    winner: string,
    equality: boolean,
    game_data?: GameData,
    opponent: PlayerData,
    game_state: GameState,
    me: PlayerData
    voies_data: VoiesData,
    roomId: string,
    available_prodiges: (ProdigeData | null)[],
    history: HistoryMessageData[],
    chat_log: ChatMessageData[],
    required_actions: { me: CapaciteRequirements | null, opponent: CapaciteRequirements | null };
    //front side data
    selected_glyphe: number, // value during dragging
    selected_voie: Elements | null, //value during dragging
    maitrise_choosing: Elements | null,
    prodige_hitting: {
        element: Elements,
        isMine: boolean
        damage: number,
        pseudo: string
    } | null,
    current_selection: Array<Elements | number>//front side selection tracking during required actions
    menu_bar_displayed_section: string,
}

export interface PlayingGlypheData {
    voie: Elements,
    glyphe_value: number
}

export interface PlayerPlayingGlypheData {
    pseudo: string,
    playingData: PlayingGlypheData
}

export interface ChatMessageData {
    pseudo: string;
    message: string;
    timestamp: number;
}

export interface HistoryMessageData {
    timestamp: number;
    message: string;
}

export interface CapaciteRequirements {
    text: string;
    target_zone: "hand" | "voie",
    target_number: number,
    already_selected: Array<Elements | number>,
    min_target_number: number
}

export interface CapaciteRequirementsSocketData {
    pseudo: string,
    requirementsData: CapaciteRequirements
}

export interface ValueUpdateData {
    pseudo: string,
    value: number
}

const state: GameStateData = {
    opponent_left_game: false,
    confirm_exit: false,
    paused: false,
    pause_interval: 0,
    menu_data: {
        menu_bar_opened: false,
        new_chat_message: false,
    },
    spectator_mode: false,
    all_images_loaded: false,
    winner: '',
    equality: false,
    game_state: {} as GameState,
    voies_data: {
        air: undefined,
        eau: undefined,
        terre: undefined,
        feu: undefined
    },
    me: {
        my_turn: false,
        voie_regard: null,
        clicked_glyphes: [],
        clicked_voies: [],
        prodiges: {},
        played_prodige: "",
        pseudo: "",
        hand: [0, 0, 0, 0, 0, 0],
        hp: 10,
        voies: {
            air: -1,
            eau: -1,
            terre: -1,
            feu: -1
        }
    },
    opponent: {
        my_turn: false,
        voie_regard: null,
        clicked_glyphes: [],
        clicked_voies: [],
        prodiges: {},
        played_prodige: "",
        pseudo: "",
        hand: [0, 0, 0, 0, 0, 0],
        hp: 10,
        voies: {
            air: -1,
            eau: -1,
            terre: -1,
            feu: -1
        }
    },
    available_prodiges: [],
    roomId: '',
    history: [],
    chat_log: [],
    required_actions: {
        me: null,
        opponent: null
    },
    //front side actions
    selected_glyphe: -1,
    selected_voie: null,
    maitrise_choosing: null,
    prodige_hitting: null,
    current_selection: [],
    menu_bar_displayed_section: ""
};

export interface GameStateGetters extends GetterTree<GameStateData, RootStateData> {
    player_prodiges: (state: GameStateData) => ProdigeData[];
    opp_prodiges: (state: GameStateData) => ProdigeData[];
    played_opp_prodige: (state: GameStateData) => ProdigeData;
    played_player_prodige: (state: GameStateData) => ProdigeData;
}

export const gameGetters: GameStateGetters = {
    player_prodiges: function (state: GameStateData): ProdigeData[] {
        const prodiges: ProdigeData[] = [];
        for (const prodigeName in state.me.prodiges) {
            prodiges.push(state.me.prodiges[prodigeName]);
        }

        return prodiges;
    },
    opp_prodiges: function (state: GameStateData): ProdigeData[] {
        const prodiges: ProdigeData[] = [];
        for (const prodigeName in state.opponent.prodiges) {
            prodiges.push(state.opponent.prodiges[prodigeName]);
        }

        return prodiges;
    },
    played_opp_prodige: function (state: GameStateData): ProdigeData {
        return state.opponent.prodiges[state.opponent.played_prodige];
    },
    played_player_prodige: function (state: GameStateData): ProdigeData {
        return state.me.prodiges[state.me.played_prodige];
    }
};

export const gameStateMutations = {
    set_all_images_loaded(state: GameStateData) {
        state.all_images_loaded = true;
    },
    set_room_id(state: GameStateData, roomId: string) {
        state.roomId = roomId;
    },
    set_spectator_mode(state: GameStateData) {
        state.spectator_mode = true;
    },
    reset_my_prodiges(state: GameStateData) {
        state.me.prodiges = {};
    },
    reset_opp_prodiges(state: GameStateData) {
        state.opponent.prodiges = {};
    },
    add_to_my_prodiges(state: GameStateData, prodige: ProdigeData) {
        const name = prodige.name
        state.me.prodiges[name] = prodige;
        for (let i = 0; i < state.available_prodiges.length; i++) {
            const prodige_data = state.available_prodiges[i];
            if (prodige_data !== null && prodige_data.name === name) {
                state.available_prodiges[i] = null
            }
        }
    },
    add_to_opp_prodiges(state: GameStateData, prodige: ProdigeData) {
        const name = prodige.name
        state.opponent.prodiges[prodige.name] = prodige;
        for (let i = 0; i < state.available_prodiges.length; i++) {
            const prodige_data = state.available_prodiges[i];
            if (prodige_data !== null && prodige_data.name === name) {
                state.available_prodiges[i] = null
            }
        }
    },
    set_prodiges(state: GameStateData, list_prodiges: ProdigeData[]) {
        state.available_prodiges = list_prodiges
    },
    set_turn_data(state: GameStateData, turn_data: TurnData) {
        for (const [key, value] of Object.entries(turn_data)) {
            if (key === state.me.pseudo) {
                state.me.my_turn = value
            } else if (key === state.opponent.pseudo) {
                state.opponent.my_turn = value
            }
        }
    },
    setOpponentName(state: GameStateData, pseudo: string) {
        state.opponent.pseudo = pseudo;
    },
    set_game_data(state: GameStateData, data: GameData) {
        state.opponent = data.opponent;
        state.me = data.me;
        state.game_state = data.game_state;
        state.game_data = data;
        state.voies_data = data.voies_data;
        state.required_actions = data.required_actions
        if (state.required_actions.me?.already_selected !== undefined) {
            state.current_selection = state.required_actions.me?.already_selected
        }
    },
    set_played_prodige(state: GameStateData, prodige_name: string) {
        state.me.played_prodige = prodige_name;
    },
    set_opp_played_prodige(state: GameStateData, prodige_name: string) {
        state.opponent.played_prodige = prodige_name;
    },
    set_game_state(state: GameStateData, game_state: GameState) {
        state.game_state = game_state;
        if (state.game_state.phase_name === "CleaningPhase") {
            console.log('cleaning prodige');
            state.prodige_hitting = null;
        }
    },
    set_selected_glyphe(state: GameStateData, selected_glyphe: number) {
        state.selected_glyphe = selected_glyphe;
    },
    set_selected_voie(state: GameStateData, selected_voie: Elements) {
        state.selected_voie = selected_voie;
    },
    set_opp_glyphe(state: GameStateData, data: PlayingGlypheData) {
        const glypheValue = data.glyphe_value;
        const voie = data.voie;
        if (state.opponent.voies[voie] != -1 && glypheValue === -1) { //glyphe removed from play, put back in hand
            state.opponent.hand[state.opponent.voies[voie]]++;
        }
        if (glypheValue >= 0) { // new glyphe played, removed from hand
            state.opponent.hand[glypheValue]--;
        }
        state.opponent.voies[voie] = glypheValue;
    },
    set_player_glyphe(state: GameStateData, data: PlayingGlypheData) {
        const glypheValue = data.glyphe_value;
        const voie = data.voie;
        if (state.me.voies[voie] != -1) { //glyphe removed from play, put back in hand
            state.me.hand[state.me.voies[voie]]++;
        }
        state.me.hand[glypheValue]--;
        state.me.voies[voie] = glypheValue;
    },
    set_prodige_hitting(state: GameStateData, data: ValueUpdateData) {
        if (data == null) {
            state.prodige_hitting = null;
        } else {
            const isMine = state.me.pseudo === data.pseudo;
            state.prodige_hitting = {
                isMine,
                pseudo: data.pseudo,
                damage: data.value,
                element: isMine ? gameGetters.played_player_prodige(state)?.element : gameGetters.played_opp_prodige(state)?.element
            }
        }
    },
    add_history_message(state: GameStateData, message: HistoryMessageData) {
        state.history.unshift(message);
    },
    click_glyphe(state: GameStateData, glyphe_value: number) {
        state.me.clicked_glyphes.push(glyphe_value);
    },
    add_glyphe_voie_target(state: GameStateData, voie: Elements) {
        state.current_selection.push(voie);
    },
    remove_glyphe_voie_target(state: GameStateData, voie: Elements) {
        state.current_selection = state.current_selection.filter(elem => voie !== elem);
    },
    voie_resolved(state: GameStateData, {voie, from}) {
        const pseudo = from === 'me' ? state.me.pseudo : state.opponent.pseudo;
        if (state.game_state.targets_available !== undefined) {
            state.game_state.targets_available[pseudo] = state.game_state.targets_available[pseudo].filter(
                (target: ResolutionTargets) => target !== voie
            )
        }
    },
    set_available_targets(state: GameStateData, payload: AvailableTargets) {
        if (state.game_state.targets_available !== undefined) {
            state.game_state.targets_available = payload
        }
    },
    set_capacite_requirements(state: GameStateData, payload: CapaciteRequirements) {
        state.required_actions.me = payload;
        if (payload && payload.already_selected) {
            state.current_selection = payload.already_selected
        } else {
            state.current_selection = [];
        }
    },
    set_opp_capacite_requirements(state: GameStateData, payload: CapaciteRequirements) {
        state.required_actions.opponent = payload;
    },
    set_opp_glyphes_for_revelation(state: GameStateData, payload: VoiesElements) {
        state.opponent.voies = payload;
    },
    set_hand_counters(state: GameStateData, payload: PlayerHand) {
        state.me.hand = payload;
    },
    set_opp_hand_counters(state: GameStateData, payload: PlayerHand) {
        state.opponent.hand = payload;
    },
    set_maitrise_choosing(state: GameStateData, maitrise: Elements | null) {
        state.maitrise_choosing = maitrise;
    },
    set_power(state: GameStateData, payload: ValueUpdateData) {
        if (payload.pseudo === state.me.pseudo) {
            state.me.prodiges[state.me.played_prodige].power = payload.value
        } else if (payload.pseudo === state.opponent.pseudo) {
            state.opponent.prodiges[state.opponent.played_prodige].power = payload.value
        }
    },
    set_damage(state: GameStateData, payload: ValueUpdateData) {
        if (payload.pseudo === state.me.pseudo) {
            state.me.prodiges[state.me.played_prodige].damage = payload.value
        } else if (payload.pseudo === state.opponent.pseudo) {
            state.opponent.prodiges[state.opponent.played_prodige].damage = payload.value
        }
    },
    set_hp(state: GameStateData, payload: ValueUpdateData) {
        if (payload.pseudo === state.me.pseudo) {
            state.me.hp = payload.value
        } else if (payload.pseudo === state.opponent.pseudo) {
            state.opponent.hp = payload.value
        }
    },
    talent_stopped(state: GameStateData, payload: { pseudo: string }) {
        if (payload.pseudo === state.me.pseudo) {
            state.me.prodiges[state.me.played_prodige].talent.stopped = true
        } else if (payload.pseudo === state.opponent.pseudo) {
            state.opponent.prodiges[state.opponent.played_prodige].talent.stopped = true
        }
    },
    maitrise_stopped(state: GameStateData, payload: { pseudo: string }) {
        if (payload.pseudo === state.me.pseudo) {
            state.me.prodiges[state.me.played_prodige].maitrise.stopped = true
        } else if (payload.pseudo === state.opponent.pseudo) {
            state.opponent.prodiges[state.opponent.played_prodige].maitrise.stopped = true
        }
    },
    add_to_current_selection(state: GameStateData, selection: Elements | number) {
        state.current_selection.push(selection);
    },
    remove_from_current_selection(state: GameStateData, selection: Elements | number) {
        state.current_selection.splice(state.current_selection.indexOf(selection), 1);
    },
    clear_current_selection(state: GameStateData) {
        state.current_selection = [];
    },
    set_players_data(state: GameStateData, payload: { [pseudo: string]: PlayerData }) {
        state.me = payload[state.me.pseudo]
        state.opponent = payload[state.opponent.pseudo]
    },
    set_regard_data(state: GameStateData, payload: RegardData) {
        if (payload.pseudo == state.me.pseudo) {
            state.me.voie_regard = payload.voie
        } else if (payload.pseudo == state.opponent.pseudo) {
            state.opponent.voie_regard = payload.voie
        }
    },
    set_winner_data(state: GameStateData, endgame_data: EndGameData) {
        state.winner = endgame_data.winner;
        state.equality = endgame_data.equal;
    },
    set_condition_activations(state: GameStateData, payload: ConditionActivations) {
        for (const [pseudo, value] of Object.entries(payload)) {
            if (pseudo === state.me.pseudo) {
                for (const [prodige_name, capacites] of Object.entries(value)) {
                    state.me.prodiges[prodige_name].talent.condition_validated = capacites['talent']
                    state.me.prodiges[prodige_name].maitrise.condition_validated = capacites['maitrise']
                }
            } else if (pseudo === state.opponent.pseudo) {
                for (const [prodige_name, capacites] of Object.entries(value)) {
                    state.opponent.prodiges[prodige_name].talent.condition_validated = capacites['talent']
                    state.opponent.prodiges[prodige_name].maitrise.condition_validated = capacites['maitrise']
                }
            }
        }
    },
    add_chat_message(state: GameStateData, message_data: ChatMessageData) {
        state.chat_log.push(message_data)
        if (!state.menu_data.menu_bar_opened) {
            state.menu_data.new_chat_message = true
        }
    },
    toggle_menu_bar_opened(state: GameStateData) {
        state.menu_data.menu_bar_opened = !state.menu_data.menu_bar_opened
        state.menu_data.new_chat_message = false
    },
    menu_bar_display_section(state: GameStateData, section: string) {
        state.menu_bar_displayed_section = section
    },
    pause_game(state: GameStateData, status: boolean) {
        state.paused = status;
    },
    set_pause_interval(state: GameStateData, interval: number) {
        state.pause_interval = interval
    },
    set_confirm_exit(state: GameStateData, confirmation: boolean) {
        state.confirm_exit = confirmation;
    },
    set_opponent_left_game(state: GameStateData) {
        state.opponent_left_game = true;
    },
};

const gameStore = {
    namespaced: true,
    state,
    getters: gameGetters,
    mutations: gameStateMutations,
}


export default gameStore;
