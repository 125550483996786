
import {
    useNamespacedGetters,
    useNamespacedState,
} from "vuex-composition-helpers";
import { GameStateData, GameStateGetters } from "@/stores/game";
import { isZone } from "@/types/GameTypes";
import Prodige from "@/components/prodige/Prodige.vue";
import {
    mapUnwrapGetters,
    mapUnwrapStates,
} from "@/types/vuex-composition-helpers_unwrap";
import { ProdigeData } from "@/types/Prodiges";
import { defineComponent } from "@vue/runtime-core";
import Lottie from "lottie-web";
import { ref } from "vue";

export default defineComponent({
    components: { Prodige },
    props: {
        zone_type: {
            type: String,
            required: true,
            validator: isZone,
        },
    },
    setup() {
        let { prodige_hitting } = mapUnwrapStates(
            useNamespacedState<GameStateData>("game", ["prodige_hitting"])
        );
        let { played_player_prodige, played_opp_prodige } = mapUnwrapGetters(
            useNamespacedGetters<GameStateGetters>("game", [
                "played_player_prodige",
                "played_opp_prodige",
            ])
        );

        const vfx_hit = ref<HTMLDivElement>();
        const vfx_charge = ref<HTMLDivElement>();
        return {
            vfx_hit,
            vfx_charge,
            played_player_prodige,
            played_opp_prodige,
            prodige_hitting,
        };
    },
    watch: {
        prodige_hitting: {
            immediate: true,
            deep: true,
            handler: function () {
                if (this.prodige_hitting != null) {
                    if (
                        this.prodige_hitting.isMine ===
                        (this.zone_type == "opp_zone")
                    ) {
                        setTimeout(() => {
                            if (this.vfx_hit) {
                                this.vfx_hit.innerHTML = "";
                                Lottie.loadAnimation({
                                    animationData: this.hitFxPath,
                                    container: this.vfx_hit,
                                    autoplay: true,
                                    loop: false,
                                });
                            }
                        }, 1000);
                    }
                    if (
                        this.prodige_hitting.isMine ===
                        (this.zone_type == "player_zone")
                    ) {
                        setTimeout(() => {
                            if (this.vfx_charge) {
                                this.vfx_charge.innerHTML = "";
                                Lottie.loadAnimation({
                                    animationData: require(`/src/assets/vfx/charge_anim.json`),
                                    container: this.vfx_charge,
                                    autoplay: true,
                                    loop: false,
                                });
                            }
                        }, 50);
                    }
                } else if (this.vfx_hit != null) {
                    this.vfx_hit.innerHTML = "";
                }
            },
        },
    },
    computed: {
        played_prodige(): ProdigeData {
            return this.zone_type === "player_zone"
                ? this.played_player_prodige
                : this.played_opp_prodige;
        },
        hitFxPath(): string {
            if (!this.prodige_hitting) return "";
            return require(`/src/assets/vfx/${this.prodige_hitting?.element}_hit.json`);
        },
    },
    methods: {
        launchDebugBattle(): void {
            this.$store.commit("game/set_prodige_hitting", {
                // pseudo: "Kah",
                // isMine: false,
                pseudo: "Nko",
                isMine: false,
                damage: 4,
                element: "terre",
            });
            setTimeout(() => {
                this.$store.commit("game/set_prodige_hitting", null);
            }, 3000);
        },
    },
});
