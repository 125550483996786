import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6ec4364"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prodige_stat = _resolveComponent("prodige-stat")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["prodige", [{ 'prodige--interactive' : _ctx.interactive}, 'prodige--size-' + _ctx.display, 'prodige--' + _ctx.data.element]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.select && _ctx.select(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["prodige__name", ['prodige__name--' + _ctx.data.element,]])
    }, _toDisplayString(_ctx.data.name), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["prodige__carte", ['prodige__carte--' + _ctx.data.element,]])
    }, [
      _createElementVNode("img", {
        class: "prodige__illustration",
        src: _ctx.illustration,
        draggable: "false"
      }, null, 8, _hoisted_1),
      _createVNode(_component_prodige_stat, {
        class: "prodige__left-stat",
        value: _ctx.data.power,
        baseValue: _ctx.data.base_power,
        type: "power",
        element: _ctx.data.element,
        display: _ctx.display
      }, null, 8, ["value", "baseValue", "element", "display"]),
      _createVNode(_component_prodige_stat, {
        class: "prodige__right-stat",
        value: _ctx.data.damage,
        baseValue: _ctx.data.base_damage,
        type: "damage",
        element: _ctx.data.element,
        display: _ctx.display
      }, null, 8, ["value", "baseValue", "element", "display"]),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["prodige__talent", [{'stopped': _ctx.data.talent.stopped}, {'condition_not_validated': !_ctx.data.talent.condition_validated}]])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["prodige__capacite", {'has-symbols': _ctx.talentHasSymbols}]),
          ref: "talent_element"
        }, null, 2)
      ], 2)), [
        [_directive_tooltip, _ctx.data.talent.description]
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["prodige__maitrise", [{'stopped': _ctx.data.maitrise.stopped}, {'condition_not_validated': !_ctx.data.maitrise.condition_validated}]])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["prodige__capacite", {'has-symbols': _ctx.maitriseHasSymbols}]),
          ref: "maitrise_element"
        }, null, 2)
      ], 2)), [
        [_directive_tooltip, _ctx.data.maitrise.description]
      ])
    ], 2)
  ], 2))
}