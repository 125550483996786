/**
 *
 * @param options the options to pass. targetSelector is the value required on the target "drag-end-action" attribute for successAction
 */
export function startDraggingGlyphe(options: {targetSelector: string, failAction: ()=> void, successAction: (target: HTMLElement) => void, glypheValue: number}){
    const glyphe = document.createElement('div');
    glyphe.classList.add('dragged-glyphe');
    glyphe.style.setProperty('--glyphePath', `url(${require( `../assets/glyphes/Glyphe_${options.glypheValue}.png`)}`)

    document.body.appendChild(glyphe);
    const mouseMove = (e: MouseEvent) => {
        glyphe.style.left = e.pageX + "px";
        glyphe.style.top = e.pageY + "px";
    };
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mouseup', (e: MouseEvent) => {
        if ((e.target as HTMLElement).getAttribute('drag-end-action') === options.targetSelector) {
            options.successAction(e.target as HTMLElement);
        }
        else {
            options.failAction();
        }
        window.removeEventListener('mousemove', mouseMove);
        document.body.removeChild(glyphe);
    }, {once: true});
}