
import LoginPanel from "@/components/login_components/LoginPanel.vue";
import Credits from "@/components/shared/Credits.vue";
import Faq from "@/components/shared/Faq.vue"
import {defineComponent} from "@vue/runtime-core";
import {useNamespacedState, useState} from "vuex-composition-helpers";
import {LobbyStateData} from "@/stores/lobby";
import {RootStateData} from "@/stores/global";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";


export default defineComponent({
    setup() {
        document.title = `Dojo, le duel des maîtres`;
        let {player_pseudo} = mapUnwrapStates(useState<RootStateData>(['player_pseudo']));
        let {currentPage} = mapUnwrapStates(useNamespacedState<LobbyStateData>('lobby', ['currentPage']));
        return {
            player_pseudo,
            currentPage
        }
    },
    data() {
        return {
            displayed_panel: "welcome"
        }
    },
    components: {
        LoginPanel,
        Credits,
        Faq
    },
    methods: {
        displayLobby() {
            this.displayed_panel = 'lobby'
        }
        ,
        change_page(pop_up_name: string) {
            this.$store.commit("lobby/setCurrentPage", pop_up_name)
        },
    }
})
