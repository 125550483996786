
import {defineComponent} from "@vue/runtime-core";
import {useNamespacedState} from "vuex-composition-helpers";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {GameStateData} from "@/stores/game";

export default defineComponent({
    setup() {
        const {all_images_loaded} = mapUnwrapStates(useNamespacedState<GameStateData>('game', ['all_images_loaded']));
        return {
            all_images_loaded
        }
    },
    computed: {
        showPreload: function (): boolean {
            return !this.all_images_loaded
        }
    }
})

