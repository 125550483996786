import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31cf83c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "waiting-zone" }
const _hoisted_2 = { class: "waiting-zone__content" }
const _hoisted_3 = {
  key: 1,
  class: "slot_empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prodige = _resolveComponent("Prodige")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prodiges, (prodige) => {
        return (_openBlock(), _createElementBlock("div", {
          key: prodige.name
        }, [
          (prodige.name !== _ctx.player.played_prodige)
            ? (_openBlock(), _createBlock(_component_Prodige, {
                key: 0,
                class: _normalizeClass(["waiting-zone__prodige", {'waiting-zone__prodige--disabled': prodige.exhausted}]),
                display: "small",
                interactive: _ctx.interactiveProdige && !prodige.exhausted,
                data: prodige,
                "click-event": _ctx.play_prodige
              }, null, 8, ["class", "interactive", "data", "click-event"]))
            : _createCommentVNode("", true),
          (prodige.name === _ctx.player.played_prodige)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}