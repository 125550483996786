import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlypheCount = _resolveComponent("GlypheCount")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.zone_type, "glyphes_zone"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.glyphes, (count, index) => {
      return (_openBlock(), _createBlock(_component_GlypheCount, {
        zone_type: _ctx.zone_type,
        key: index,
        count: count,
        value: index
      }, null, 8, ["zone_type", "count", "value"]))
    }), 128))
  ], 2))
}