const zones = ['opp_zone', 'player_zone'] as const;

export type Zone = typeof zones[number];

export type Elements = keyof VoiesElements;
export function isElement(str: string): str is Elements {
    return  str === "air" || str === "eau" || str === "terre" || str === "feu";
}

export interface VoiesElements {
    air: number,
    eau: number,
    terre: number,
    feu: number
}

export function isZone(maybeZone: unknown): maybeZone is Zone {
    return typeof maybeZone === 'string' && zones.includes(maybeZone as Zone);
}