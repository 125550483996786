import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f84c2304"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game" }
const _hoisted_2 = { class: "battlefield" }
const _hoisted_3 = { class: "voies" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpectatorScreen = _resolveComponent("SpectatorScreen")!
  const _component_MenuBar = _resolveComponent("MenuBar")!
  const _component_DisplayedSection = _resolveComponent("DisplayedSection")!
  const _component_PauseScreen = _resolveComponent("PauseScreen")!
  const _component_ConfirmExitScreen = _resolveComponent("ConfirmExitScreen")!
  const _component_ExitScreen = _resolveComponent("ExitScreen")!
  const _component_GameZone = _resolveComponent("GameZone")!
  const _component_Voie = _resolveComponent("Voie")!
  const _component_RequiredAction = _resolveComponent("RequiredAction")!
  const _component_MaitriseChoice = _resolveComponent("MaitriseChoice")!
  const _component_EndGameScreen = _resolveComponent("EndGameScreen")!
  const _component_RoundedButton = _resolveComponent("RoundedButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.spectator_mode)
      ? (_openBlock(), _createBlock(_component_SpectatorScreen, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_MenuBar),
    _createVNode(_component_DisplayedSection),
    (_ctx.paused)
      ? (_openBlock(), _createBlock(_component_PauseScreen, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.confirm_exit)
      ? (_openBlock(), _createBlock(_component_ConfirmExitScreen, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.opponent_left_game && !_ctx.confirm_exit)
      ? (_openBlock(), _createBlock(_component_ExitScreen, { key: 3 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_GameZone, { zone_type: "opp_zone" }),
      _createVNode(_component_GameZone, { zone_type: "player_zone" }),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voies, (voie) => {
          return (_openBlock(), _createBlock(_component_Voie, {
            key: voie,
            element: voie
          }, null, 8, ["element"]))
        }), 128))
      ]),
      _createVNode(_component_RequiredAction),
      _createVNode(_component_MaitriseChoice),
      _createVNode(_component_EndGameScreen),
      (_ctx.showValidButton)
        ? (_openBlock(), _createBlock(_component_RoundedButton, {
            key: 0,
            class: "game__valid-button",
            "click-event": _ctx.validateAction,
            text: _ctx.validButtonLabel
          }, null, 8, ["click-event", "text"]))
        : _createCommentVNode("", true)
    ])
  ]))
}