import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Credits = _resolveComponent("Credits")!
  const _component_Faq = _resolveComponent("Faq")!

  return (_ctx.menu_bar_displayed_section !== '')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "displayed_section",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        (_ctx.menu_bar_displayed_section === 'credits')
          ? (_openBlock(), _createBlock(_component_Credits, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.menu_bar_displayed_section === 'faq')
          ? (_openBlock(), _createBlock(_component_Faq, { key: 1 }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}