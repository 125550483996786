import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-183fb6f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "voie" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlypheVoie = _resolveComponent("GlypheVoie")!
  const _component_VoieImg = _resolveComponent("VoieImg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GlypheVoie, {
      zone_type: "opp_zone",
      element: _ctx.element
    }, null, 8, ["element"]),
    _createVNode(_component_VoieImg, { element: _ctx.element }, null, 8, ["element"]),
    _createVNode(_component_GlypheVoie, {
      zone_type: "player_zone",
      element: _ctx.element
    }, null, 8, ["element"])
  ]))
}