
import { defineComponent, PropType } from "@vue/runtime-core";
import { Elements, isElement, isZone, Zone } from "@/types/GameTypes";
import { useNamespacedState } from "vuex-composition-helpers";
import { mapUnwrapStates } from "@/types/vuex-composition-helpers_unwrap";
import { GameStateData } from "@/stores/game";
import { startDraggingGlyphe } from "@/utils/glypheDragDrop";
import socket from "@/utils/socket";

export default defineComponent({
    props: {
        zone_type: {
            type: String as PropType<Zone>,
            required: true,
            validator: isZone,
        },
        element: {
            type: String as PropType<Elements>,
            required: true,
        },
    },
    setup() {
        let {
            me,
            opponent,
            selected_glyphe,
            game_state,
            required_actions,
            selected_voie,
            current_selection,
        } = mapUnwrapStates(
            useNamespacedState<GameStateData>("game", [
                "me",
                "opponent",
                "selected_glyphe",
                "game_state",
                "required_actions",
                "selected_voie",
                "current_selection",
            ])
        );

        return {
            me,
            opponent,
            selected_glyphe,
            game_state,
            selected_voie,
            required_actions,
            current_selection,
        };
    },
    methods: {
        startRemoveGlyphe(): void {
            if (this.isPlayingGlpyhes) {
                this.$store.commit("game/set_selected_voie", this.element);
                startDraggingGlyphe({
                    targetSelector: "play-glyphe",
                    glypheValue: this.glypheValue,
                    failAction: () => {
                        this.$store.commit("game/set_selected_voie", null);
                        socket.game.playingGlypheVoie(-1, this.element);
                    },
                    successAction: (target: HTMLElement) => {
                        this.$store.commit("game/set_selected_voie", null);
                        let data = target.getAttribute("data-element");
                        if (data && isElement(data)) {
                            socket.game.switchingGlyphesVoies(
                                this.element,
                                data
                            );
                        }
                    },
                });
            }
        },
        removeGlyphe() {
            socket.game.removeGlypheVoieTarget(this.element);
        },
        selectGlyphe(e: MouseEvent): void {
            if (this.isSelectedGlypheRequiredAction) {
                if (this.glypheValue > 0) {
                    if (e.button === 0) {
                        // left click
                        if (!this.current_selection.includes(this.element)) {
                            socket.game.addGlypheVoieTarget(this.element);
                        }
                    }
                }
            }
        },
    },

    computed: {
        isPlayingGlpyhes(): boolean {
            return (
                this.zone_type === "player_zone" &&
                this.game_state.phase_name === "PlayingGlyphes" &&
                (this.selected_glyphe >= 0 ||
                    this.selected_voie != null ||
                    this.glypheValue >= 0)
            );
        },
        isSelectedGlypheRequiredAction(): boolean {
            return (
                this.zone_type === "player_zone" &&
                ["RoundResolution"].includes(this.game_state.phase_name) &&
                !!this.required_actions.me &&
                this.required_actions.me.target_zone === "voie"
            );
        },
        interactive(): boolean {
            return this.isPlayingGlpyhes || this.isSelectedGlypheRequiredAction;
        },
        glypheValue(): number {
            return this.zone_type === "player_zone"
                ? this.me.voies[this.element]
                : this.opponent.voies[this.element];
        },
        backgroundImage(): string {
            if (this.glypheValue === -1) {
                let bg: string = "none";
                if (this.isVoieRegard) {
                    bg = "url(" + require(`../../assets/Jetonregard.png`);
                }
                return bg;
            }
            return (
                "url(" +
                require(`../../assets/glyphes/Glyphe_${
                    this.glypheValue == -2 ? "Dos" : this.glypheValue
                }.png`) +
                ")"
            );
        },
        isVoieRegard(): boolean {
            if (this.zone_type === "player_zone") {
                return this.opponent.voie_regard === this.element;
            } else if (this.zone_type === "opp_zone") {
                return this.me.voie_regard === this.element;
            } else {
                return false;
            }
        },
    },
});
