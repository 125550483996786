
import {
    mapUnwrapGetters,
    mapUnwrapStates,
} from "@/types/vuex-composition-helpers_unwrap";
import {
    useNamespacedGetters,
    useNamespacedState,
} from "vuex-composition-helpers";
import { GameStateData, GameStateGetters } from "@/stores/game";
import { defineComponent, ref } from "@vue/runtime-core";
import { capitalizeFirstLetter } from "@/utils/stringUtils";
import {CapaciteData} from "@/stores/game";
import socket from "@/utils/socket";
import Lottie from "lottie-web";
import TooltipDirective from "@/components/shared/TooltipDirective";

export type Voie_State = "won" | "lost" | "draw" | "";

export default defineComponent({
    name: "VoieImg",
    directives: {
        tooltip: TooltipDirective,
    },
    props: ["element"],
    setup() {
        let { game_state, me, opponent, voies_data} = mapUnwrapStates(
            useNamespacedState<GameStateData>("game", [
                "game_state",
                "me",
                "opponent",
                "voies_data"
            ])
        );
        let { played_player_prodige } = mapUnwrapGetters(
            useNamespacedGetters<GameStateGetters>("game", [
                "played_player_prodige",
            ])
        );

        let voie_vfx = ref<HTMLDivElement>();

        return {
            voie_vfx,
            game_state,
            me,
            opponent,
            played_player_prodige,
            voies_data
        };
    },
    computed: {
        description(): string {
            const voie: CapaciteData | undefined = this.voies_data[this.element]
            let description = "";
            if (voie !== undefined) {
                description = voie.description;
            }
            return description;
        },
        image_url(): string {
            return require("/src/assets/Voie_" + capitalizeFirstLetter(this.element) + ".png");
        },
        voie_state(): Voie_State {
            if (this.game_state.phase_name === "RoundResolution") {
                if (
                    this.game_state.targets_available &&
                    this.game_state.targets_available[this.me.pseudo].includes(this.element)
                ) {
                    return "won";
                } else if (
                    this.game_state.targets_available &&
                    this.game_state.targets_available[this.opponent.pseudo].includes(this.element)
                ) {
                    return "lost";
                } else {
                    return "draw";
                }
            }
            return "";
        },
        interactive(): boolean {
            return this.voie_state === "won" && this.me.my_turn;
        },
    },
    watch: {
        voie_state: {
            immediate: true,
            deep: true,
            handler: function (newValue: Voie_State, oldValue: Voie_State) {
                if (
                    this.voie_vfx &&
                    ["draw", ""].includes(newValue) &&
                    oldValue == "won"
                ) {
                    this.voie_vfx.innerHTML = "";
                    let animation = Lottie.loadAnimation({
                        animationData: require(`/src/assets/vfx/voie_${this.element}.json`),
                        container: this.voie_vfx,
                        autoplay: false,
                        loop: false,
                    });
                    animation.setSpeed(2);
                    animation.play();

                    animation.addEventListener("complete", () => {
                        if (this.voie_vfx) {
                            this.voie_vfx.innerHTML = "";
                        }
                    });
                }
            },
        },
    },
    methods: {
        selectVoie() {
            if (this.voie_state === "won" && this.me.my_turn) {
                if (
                    this.played_player_prodige.element === this.element &&
                    this.game_state.targets_available !== undefined
                ) {
                    socket.game.checkMaitriseValid();
                } else {
                    socket.game.clickVoie(this.element);
                }
            }
        },
    },
});
