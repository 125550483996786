import {LobbyGameToSpectateData, LobbyPlayerData, RootStateData} from "@/stores/global";
import {Module, MutationTree} from "vuex";

export interface LobbyStateData {
    currentPage: 'play' | 'credits' | 'faq',
    panel: 'select-opp' | 'spectate',
    listOpp: LobbyPlayerData[],
    listGamesToSpectate: LobbyGameToSpectateData[],
    selected_opponent: string,
    selected_game_to_spectate: string,
    invitation: {
        receiving: string,
        sending: string
    },
}

const state: LobbyStateData = {
    currentPage: 'play',
    panel: 'select-opp',
    listOpp: [],
    listGamesToSpectate: [],
    selected_opponent: '',
    selected_game_to_spectate: '',
    invitation: {
        receiving: '',
        sending: ''
    }
};

export const lobbyMutations = {
    setListOpp(state: LobbyStateData, list: LobbyPlayerData[]) {
        state.listOpp = list;
    },
    setInvitation(state: LobbyStateData, pseudo: string) {
        state.invitation.receiving = pseudo;
    },
    setInvitationRequest(state: LobbyStateData, pseudo: string) {
        state.invitation.sending = pseudo;
    },
    resetMatchOffer(state: LobbyStateData) {
        state.invitation.receiving = '';
        state.invitation.sending = '';
    },
    setSelectedOpponent(state: LobbyStateData, opp_pseudo: string) {
        state.selected_opponent = opp_pseudo;
    },
    setListGameToSpectate(state: LobbyStateData, list: LobbyGameToSpectateData[]) {
        state.listGamesToSpectate = list;
    },
    setSelectedGameToSpectate(state: LobbyStateData, game_id: string) {
        state.selected_game_to_spectate = game_id;
    },
    switchPanelState(state: LobbyStateData) {
        state.panel = state.panel === 'select-opp' ? 'spectate' : 'select-opp';
    },
    setCurrentPage(state: LobbyStateData, page_name: 'play' | 'credits' | 'faq') {
        console.log(page_name)
        state.currentPage = page_name
    }
};

export const lobbyGetters = {};

export const lobbyActions = {
    socket_oppList(context, list) {
        context.commit('setListOpp', list)
    },
    socket_gamesToSpectateList(context, list) {
        context.commit('setListGameToSpectate', list)
    },
    socket_matchOffer(context, pseudo) {
        context.commit('setInvitation', pseudo);
    },
    socket_cancelMatchOffer(context) {
        context.commit('resetMatchOffer');
    },
    socket_matchOfferAccepted(context) {
        context.commit('resetMatchOffer');
    }
};

const lobbyModule: Module<LobbyStateData, RootStateData> = {
    namespaced: true,
    state,
    mutations: lobbyMutations,
    actions: lobbyActions,
    getters: lobbyGetters
};

export default lobbyModule;