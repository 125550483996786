
import {defineComponent, PropType, ref} from "@vue/runtime-core";
import {useNamespacedState, useState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";
import {RootStateData} from "@/stores/global";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {isZone, Zone} from "@/types/GameTypes";

export default defineComponent({
    props: {
        zone_type: {
            type: String as PropType<Zone>,
            validator: isZone,
            required: true
        }
    },
    setup() {
        let {opponent, me} = mapUnwrapStates(useNamespacedState<GameStateData>('game', ['opponent', 'me']));
        let {player_pseudo} = mapUnwrapStates(useState<RootStateData>(['player_pseudo']));
        let deltaHp = ref(0);

        return {
            deltaHp,
            opponent,
            me,
            player_pseudo
        }
    },
    watch: {
        hpValue: {
            immediate: true,
            handler: function (newValue: number, previousValue: number) {
                this.deltaHp = newValue - previousValue;
                setTimeout(()=> {
                    if (this.deltaHp === newValue - previousValue) this.deltaHp = 0;
                }, 1500);
            }
        }
    },
    computed: {
        hasRegard(): boolean {
            return this.zone_type === "player_zone" ? this.me.voie_regard != null : this.opponent.voie_regard != null;
        },
        hpValue(): number {
            return this.zone_type == "player_zone" ? this.me.hp : this.opponent.hp;
        },
        tokenHidden: function (): boolean {
            if (this.zone_type === "player_zone") {
                return !this.me.my_turn;
            } else {
                return !this.opponent.my_turn;
            }
        },
        display_pseudo: function (): string {
            if (!this.opponent) return "no opponent in game state";
            return this.zone_type === 'opp_zone' ? this.opponent.pseudo : this.me.pseudo;
        },
    },
});
