
import Credits from "@/components/shared/Credits.vue";
import Faq from "@/components/shared/Faq.vue";
import {defineComponent} from "@vue/runtime-core";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";

export default defineComponent({
    name: "DisplayedSection",
    setup() {
        let {menu_bar_displayed_section} = mapUnwrapStates(
            useNamespacedState<GameStateData>("game", ['menu_bar_displayed_section'])
        );
        return {menu_bar_displayed_section};
    },
    components: {
        Credits,
        Faq
    },
    methods: {
        close() {
            this.$store.commit("game/menu_bar_display_section", "")
        }
    }
})
