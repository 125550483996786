
import router from "@/router";
import {ProdigeData} from "@/types/Prodiges";
import {get_list_images_to_load, load_images, load_loading_circle,} from "@/utils/imageLoading";
import {Elements, VoiesElements} from "@/types/GameTypes";
import {PlayerData, PlayerHand} from "@/types/Player";
import {
    AvailableTargets,
    CapaciteData,
    CapaciteRequirements,
    CapaciteRequirementsSocketData,
    ChatMessageData,
    ConditionActivations, EndGameData,
    GameData,
    GameState,
    GameStateData,
    HandSocketData,
    HistoryMessageData,
    InteractingWithProdigeData,
    PlayerPlayingGlypheData,
    PlayingGlypheData,
    RegardData,
    RouteData,
    TurnData,
    ValueUpdateData,
} from "@/stores/game";
import {defineComponent} from "@vue/runtime-core";
import {mapState} from "vuex";
import socket from "@/utils/socket";
import {StoreMutations} from "@/stores/global";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {useNamespacedState} from "vuex-composition-helpers";

interface MutationData {
    mutation_name: StoreMutations;
    data: any;
    duration: number;
    delay: number; // delays the commit to the store, while letting the other events after it in queue being applied
}

export default defineComponent({
    name: "EventManager",
    data(): { queue: MutationData[]; waiting: boolean } {
        return {
            queue: [],
            waiting: false,
        };
    },
    setup() {
        let {opponent, me} = mapUnwrapStates(
            useNamespacedState<GameStateData>("game", ["opponent", "me"])
        );
        return {opponent, me};
    },
    computed: mapState("game", {
        available_prodiges(state: GameStateData) {
            return state.game_state.available_prodiges;
        }
    }),
    methods: {
        register(
            mutation_name: StoreMutations,
            data?: any,
            duration: number = 0,
            delay: number = 0
        ) {
            const mutation: MutationData = {
                mutation_name,
                data,
                duration,
                delay,
            };
            this.queue.push(mutation);
            this.apply_next_mutation();
        },
        apply_next_mutation() {
            while (!this.waiting) {
                const next_mutation: MutationData | undefined =
                    this.queue.shift();
                if (next_mutation != null) {
                    this.apply(next_mutation);
                } else {
                    break;
                }
            }
        },
        apply(mutation: MutationData) {
            const {mutation_name, data, duration, delay} = mutation;
            if (delay > 0) {
                setTimeout(() => {
                    if (data !== undefined) {
                        this.$store.commit(mutation_name, data);
                    } else {
                        this.$store.commit(mutation_name);
                    }
                }, 1000 * delay);
            } else {
                if (data !== undefined) {
                    this.$store.commit(mutation_name, data);
                } else {
                    this.$store.commit(mutation_name);
                }
            }

            if (duration > 0) {
                this.waiting = true;
                setTimeout(this.apply_callback, 1000 * duration);
            }
        },
        apply_callback() {
            this.waiting = false;
            this.apply_next_mutation();
        },
    },
    sockets: {
        join_room(roomId) {
            this.register("lobby/resetMatchOffer");
            this.register("game/set_room_id", roomId);
            router.push({path: `/draft/${roomId}`});
        },

        set_spectator_mode() {
            this.register("game/set_spectator_mode");
        },

        opp_pseudo(pseudo: string) {
            this.register("game/setOpponentName", pseudo);
        },

        selecting_prodige(data: InteractingWithProdigeData) {
            const prodige = this.available_prodiges?.find(
                (prodige) => prodige.name === data.prodigeName
            );
            const pseudo: string = data.pseudo;
            if (pseudo === this.me.pseudo) {
                this.register("game/add_to_my_prodiges", prodige);
            } else if (pseudo === this.opponent.pseudo) {
                this.register("game/add_to_opp_prodiges", prodige);
            }
        },

        available_prodiges(list_prodiges: (ProdigeData | null)[]) {
            this.register("game/set_prodiges", list_prodiges);
            const list_prodige_names: (string | null)[] = list_prodiges.map(
                (prodige) => (prodige !== null ? prodige.name : null)
            );
            let clean_list_prodige_names: string[] = [];
            for (let prodige_name of list_prodige_names) {
                if (prodige_name !== null) {
                    clean_list_prodige_names.push(prodige_name);
                }
            }
            load_loading_circle();
            const list_images = get_list_images_to_load(
                clean_list_prodige_names
            );
            load_images(list_images, this.$store);
        },

        turn_data(turn_data: TurnData) {
            this.register("game/set_turn_data", turn_data);
        },

        game_data(data: GameData) {
            this.register("game/set_game_data", data);
            this.register("game/set_room_id", data.game_id);
        },

        route(route_data: RouteData) {
            router.push("/" + route_data.route + "/" + route_data.room_id);
        },

        observer_game_data(data: GameData) {
            this.register("game/set_game_data", data);
            this.register("game/set_room_id", data.game_id);

            const phase_name: string = data.game_state.phase_name;
            switch (phase_name) {
                case "PlayersChoosingProdiges":
                    this.register(
                        "game/set_prodiges",
                        data.game_state.available_prodiges
                    );
            }

            load_loading_circle();
            const list_prodige_names = this.available_prodiges?.map(
                (prodige_data) => prodige_data.name
            );
            if (list_prodige_names) {
                const list_images = get_list_images_to_load(list_prodige_names);
                load_images(list_images, this.$store);
            }
        },

        playing_prodige(data: InteractingWithProdigeData) {
            const prodigeName: string = data.prodigeName;
            if (data.pseudo === this.me.pseudo) {
                this.register("game/set_played_prodige", prodigeName);
            } else if (data.pseudo === this.opponent.pseudo) {
                this.register("game/set_opp_played_prodige", prodigeName);
            }
        },

        update_phase(phase_data: GameState) {
            this.register("game/set_game_state", phase_data);
        },

        playing_glyphe_voie(data: PlayerPlayingGlypheData) {
            const playingData: PlayingGlypheData = data.playingData;
            if (data.pseudo === this.me.pseudo) {
                this.register("game/set_player_glyphe", playingData);
            } else if (data.pseudo === this.opponent.pseudo) {
                this.register("game/set_opp_glyphe", playingData);
            }
        },

        add_history_message(message: HistoryMessageData) {
            this.register("game/add_history_message", message);
        },

        click_glyphe_hand(glyphe_value: number) {
            this.register("game/click_glyphe", glyphe_value);
        },

        add_glyphe_voie(voie: string) {
            this.register("game/add_glyphe_voie_target", voie);
        },

        remove_glyphe_voie(voie: string) {
            this.register("game/remove_glyphe_voie_target", voie);
        },

        add_glyphe_target(glyphe_value: number) {
            this.register("game/add_to_current_selection", glyphe_value);
        },

        remove_glyphe_target(glyphe_value: number) {
            this.register("game/remove_from_current_selection", glyphe_value);
        },

        capacite_requirements(payload: CapaciteRequirementsSocketData) {
            const cleaned_payload: CapaciteRequirements | null =
                payload.requirementsData == null
                    ? null
                    : payload.requirementsData;
            if (payload.pseudo === this.me.pseudo) {
                this.register(
                    "game/set_capacite_requirements",
                    cleaned_payload
                );
            } else if (payload.pseudo === this.opponent.pseudo) {
                this.register(
                    "game/set_opp_capacite_requirements",
                    cleaned_payload
                );
            }
        },

        opp_glyphes_revelation(payload: VoiesElements) {
            this.register("game/set_opp_glyphes_for_revelation", payload);
        },

        hand_update(payload: HandSocketData) {
            const hand: PlayerHand = payload.hand;
            if (payload.pseudo === this.me.pseudo) {
                this.register("game/set_hand_counters", hand);
            } else if (payload.pseudo === this.opponent.pseudo) {
                this.register("game/set_opp_hand_counters", hand);
            }
        },

        power_update(payload: ValueUpdateData) {
            this.register("game/set_power", payload);
        },

        damage_update(payload: ValueUpdateData) {
            this.register("game/set_damage", payload);
        },

        talent_stopped(payload: { pseudo: string }) {
            this.register("game/talent_stopped", payload);
        },

        maitrise_stopped(payload: { pseudo: string }) {
            this.register("game/maitrise_stopped", payload);
        },

        maitrise_validation(valid: boolean) {
            const played_prodige: string = this.me.played_prodige;
            const element: Elements = this.me.prodiges[played_prodige].element;
            if (valid) {
                this.register("game/set_maitrise_choosing", element);
            } else {
                socket.game.clickVoie(element);
            }
        },

        update_players_data(payload: { [pseudo: string]: PlayerData }) {
            this.register("game/set_players_data", payload);
        },

        update_regard_data(payload: RegardData) {
            this.register("game/set_regard_data", payload);
        },

        update_available_targets(payload: AvailableTargets) {
            this.register("game/set_available_targets", payload);
        },

        finish(endgame_data: EndGameData) {
            this.register("game/set_winner_data", endgame_data);
        },
        condition_activations(payload: ConditionActivations) {
            this.register("game/set_condition_activations", payload);
        },
        chat_message(message_data: { pseudo: string; message: string }) {
            const data_timestamped: ChatMessageData = {
                message: message_data.message,
                pseudo: message_data.pseudo,
                timestamp: Date.now(),
            };
            this.register("game/add_chat_message", data_timestamped);
        },
        pause_game(data: { status: boolean }) {
            this.register("game/pause_game", data.status)
            const pseudo = this.opponent.pseudo
            const message = data.status ? pseudo + ' s\'est déconnecté.' : pseudo + ' s\'est reconnecté.'
            const message_data: HistoryMessageData = {
                message: message,
                timestamp: Date.now()
            }
            this.register('game/add_history_message', message_data)
        },
        player_leaves_game(data: { pseudo: string }) {
            if (data.pseudo === this.opponent.pseudo) {
                this.$store.commit('game/set_opponent_left_game')
            }
        },
        capacite_applied(capacite_data: CapaciteData) {
            // History Message
            if (capacite_data.source.type === "prodige_damage") {
                capacite_data.text =
                    capacite_data.source.entity +
                    " inflige " +
                    capacite_data["value"] +
                    " Dégâts";
            } else if (capacite_data.modificator) {
                capacite_data.text = capacite_data.text_without_modificator;
            }
            const message: HistoryMessageData = {
                timestamp: capacite_data.timestamp,
                message: capacite_data.owner + " : " + capacite_data.text,
            };
            this.register("game/add_history_message", message);

            // HP up and down
            const effect: string = capacite_data.effect;
            if (effect === "vampirisme") {
                // Timing definition
                let hpTiming: number = 0;
                let hpDelay: number = 0;
                switch (capacite_data.source.type) {
                    case "maitrise":
                        hpTiming = 0.3;
                        hpDelay = 0.35;
                        break;
                    case "talent":
                        hpTiming = 0.2;
                        break;
                }

                // ValueUpdateData generation
                let player: PlayerData | null = null;
                let opp: PlayerData | null = null;
                if (capacite_data.owner === this.me.pseudo) {
                    if (capacite_data.target_player === "me") {
                        player = this.me;
                        opp = this.opponent;
                    } else {
                        player = this.opponent;
                        opp = this.me;
                    }
                } else if (capacite_data.owner === this.opponent.pseudo) {
                    if (capacite_data.target_player === "me") {
                        player = this.opponent;
                        opp = this.me;
                    } else {
                        player = this.me;
                        opp = this.opponent;
                    }
                }
                if (player !== null && opp !== null) {
                    // Target player
                    const payload_target: ValueUpdateData = {
                        pseudo: player.pseudo,
                        value: player.hp - capacite_data.value,
                    };
                    this.register("game/set_hp", payload_target, 0, 0);

                    // Opponent of target player
                    const payload_opp: ValueUpdateData = {
                        pseudo: opp.pseudo,
                        value: opp.hp + capacite_data.value,
                    };
                    this.register("game/set_hp", payload_opp, hpTiming, hpDelay);
                }
            } else if (["hp_down", "hp_up"].includes(effect)) {
                // Timing definition
                let hpTiming: number = 0;
                let hpDelay: number = 0;
                switch (capacite_data.source.type) {
                    case "prodige_damage":
                        hpTiming = 1.9;
                        hpDelay = 0;
                        this.register(
                            "game/set_prodige_hitting",
                            {pseudo: capacite_data.owner, value: capacite_data.value,},
                            2.5, 1.5
                        );
                        break;
                    case "maitrise":
                        hpTiming = 0.3;
                        hpDelay = 0.35;
                        break;
                    case "talent":
                        hpTiming = 0.2;
                        break;
                    case "voie":
                        hpTiming = 0.25;
                        hpDelay = 1.3;
                        break;
                }

                // ValueUpdateData generation
                let target_pseudo: string = "";
                let player: PlayerData | null = null;
                if (capacite_data.owner === this.me.pseudo) {
                    player = capacite_data.target_player === "me" ? this.me : this.opponent;
                } else if (capacite_data.owner === this.opponent.pseudo) {
                    player = capacite_data.target_player === "me" ? this.opponent : this.me;
                }
                if (player !== null) {
                    target_pseudo = player.pseudo;

                    const payload: ValueUpdateData = {
                        pseudo: target_pseudo,
                        value: capacite_data.final_value
                    };
                    this.register("game/set_hp", payload, hpTiming, hpDelay);
                }
            }
        },
    },
});
