
import {defineComponent, onMounted, PropType, watch} from "@vue/runtime-core";
import {ProdigeCapacity, ProdigeClickHandler, ProdigeData} from "@/types/Prodiges";
import ProdigeStat from "@/components/prodige/ProdigeStat.vue";
import socket from "@/utils/socket";
import TooltipDirective from "@/components/shared/TooltipDirective";
import {createApp, ref} from "vue";
import {parseCapacitiesInString} from "@/utils/CapacityParsing";

export default defineComponent({
    components: {ProdigeStat},
    directives: {
        tooltip: TooltipDirective,
    },
    setup(props: { data: ProdigeData, display: string }) {
        const talent_element = ref<HTMLDivElement>();
        const maitrise_element = ref<HTMLDivElement>();
        let talent = ref<ProdigeCapacity>();
        let maitrise = ref<ProdigeCapacity>();

        let talentHasSymbols = ref<boolean>(false);
        let maitriseHasSymbols = ref<boolean>(false);

        function mapCapacity(capacity: ProdigeCapacity): Array<string | HTMLDivElement> {
            let capacityText = capacity.text;
            let result: Array<string | HTMLDivElement> = [];
            const capacities = parseCapacitiesInString(capacityText);
            if (capacities.length > 0) {
                capacities.forEach(([fullMatch, type, value]: string[]) => {
                    let beforeText = capacityText.split(fullMatch)[0];
                    if (beforeText && beforeText.length > 0) {
                        //add text before capacity
                        result.push(beforeText);
                        //remove before text + symbol text
                        capacityText = capacityText.replace(beforeText, "").replace(fullMatch, "");
                    }
                    const wrapper = document.createElement('div');
                    const elementApp = createApp(ProdigeStat, {
                        type: type,
                        display: props.display,
                        value: Math.abs(parseInt(value)),
                        baseValue: Math.abs(parseInt(value)),
                        operation: parseInt(value) > 0 ? "+" : "-"
                    });
                    elementApp.mount(wrapper);
                    result.push(wrapper);
                });
                return result;
            } else return [capacity.text];
        }

        function computeCapacity(capacity: ProdigeCapacity, target: HTMLElement): boolean {

            if (capacity.value == undefined) return false;
            let mapped = mapCapacity(capacity);
            let hasSymbols = false;
            mapped.forEach(element => {
                if (target) {
                    if (typeof element === "string") {
                        let span = document.createElement("span");
                        span.innerHTML = element;
                        target.appendChild(span);
                    } else {
                        target.appendChild(element);
                        hasSymbols = true;
                    }
                }
            });
            return hasSymbols;
        }

        watch(talent, (capacity) => {
            if (capacity && talent_element.value) talentHasSymbols.value = computeCapacity(capacity, talent_element.value);
        });
        watch(maitrise, (capacity) => {
            if (capacity && maitrise_element.value) maitriseHasSymbols.value = computeCapacity(capacity, maitrise_element.value);
        });
        onMounted(() => {
            talent.value = props.data.talent;
            maitrise.value = props.data.maitrise;
        });
        return {
            talent,
            maitrise,
            talentHasSymbols,
            maitriseHasSymbols,
            talent_element,
            maitrise_element
        }
    },
    mounted() {
        this.talent = this.data.talent;
        this.maitrise = this.data.maitrise;
    },
    props: {
        data: {
            type: Object as PropType<ProdigeData>,
            required: true
        },
        //big or small, default is small
        display: {
            type: String,
            default: "small"
        },
        interactive: {
            type: Boolean
        },
        clickEvent: {
            type: Function as PropType<ProdigeClickHandler>,
            required: false
        }
    },
    computed: {
        illustration: function (): string {
            if (this.display === "small") {
                return require(`/src/assets/prodiges/cercle/` + this.data.name + `_Cercle.png`);
            } else {
                return require(`/src/assets/prodiges/` + this.data.name + `.png`);
            }
        },
    },
    methods: {

        select: function (): void {
            if (this.interactive) {
                if (this.clickEvent) {
                    this.clickEvent(this.data.name);
                } else {
                    socket.game.selectProdige(this.data.name);
                }
            }
        }
    }
})
