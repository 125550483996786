
import {computed, defineComponent} from "@vue/runtime-core";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData, HistoryMessageData} from "@/stores/game";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {parseCapacitiesInString} from "@/utils/CapacityParsing";

export default defineComponent({
    setup() {
        let gameRefs = (useNamespacedState<GameStateData>("game", ["history"]));
        let {history} = mapUnwrapStates(gameRefs);

        function formatMessage(message: string): string {
            const capacities = parseCapacitiesInString(message);
            console.log(capacities);
            let formattedMessage = message;
            capacities.forEach(([fullMatch, name, value]) => {
                const numericValue = parseInt(value);
                const plural = Math.abs(numericValue) > 1 ? 's': '';
                const sign = numericValue >= 0 ? "+" : "";
                let sentence = "";
                if (name == "pv") {
                    sentence = `${sign}${numericValue} point${plural} de vie`;
                }
                else if (name == "damage" ) {
                    sentence = `${sign}${numericValue} dégât${plural} `;
                }
                else if (name == "power") {
                    sentence = `${sign}${numericValue} puissance`;
                }
                formattedMessage = formattedMessage.replace(fullMatch, " " + sentence + " ");
            });
            return formattedMessage;
        }

        let formattedHistory = computed(() => {
            return gameRefs.history.value.map((message) => {
                return {
                    formattedMessage: formatMessage(message.message),
                    message: message.message,
                    timestamp: message.timestamp
                }
            });
        });
        return {
            formattedHistory,
            history
        }
    },
    methods: {
        formattedTimestamp(message: HistoryMessageData): string {
            return new Date(message.timestamp).toLocaleTimeString();
        }
    }
});
