import {createRouter, createWebHistory} from 'vue-router'

import Game from "@/components/Game.vue";
import Login from "@/components/Login.vue";
import Draft from "@/components/Draft.vue";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/draft/:roomId',
        name: 'Draft',
        component: Draft,
        props: true
    },
    {
        path: '/play/:roomId',
        name: 'Play',
        component: Game,
        props: true
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
