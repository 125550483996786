import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PseudoZone = _resolveComponent("PseudoZone")!
  const _component_GlyphesZone = _resolveComponent("GlyphesZone")!
  const _component_CurrentProdigeZone = _resolveComponent("CurrentProdigeZone")!
  const _component_WaitingProdigeZone = _resolveComponent("WaitingProdigeZone")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["game_zone", _ctx.zone_type])
  }, [
    _createVNode(_component_PseudoZone, { zone_type: _ctx.zone_type }, null, 8, ["zone_type"]),
    _createVNode(_component_GlyphesZone, { zone_type: _ctx.zone_type }, null, 8, ["zone_type"]),
    _createVNode(_component_CurrentProdigeZone, { zone_type: _ctx.zone_type }, null, 8, ["zone_type"]),
    _createVNode(_component_WaitingProdigeZone, { zone_type: _ctx.zone_type }, null, 8, ["zone_type"])
  ], 2))
}