import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "target-glyphe",
    style: _normalizeStyle({backgroundImage: _ctx.backgroundImage}),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeGlyphe && _ctx.removeGlyphe(...args)))
  }, null, 4))
}