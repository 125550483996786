
import {isZone} from "@/types/GameTypes";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";
import {defineComponent} from "@vue/runtime-core";
import Prodige from "@/components/prodige/Prodige.vue";
import {ProdigeData} from "@/types/Prodiges";
import socket from "@/utils/socket";
import {PlayerData} from "@/types/Player";

export default defineComponent({
  components: {Prodige},
  props: {
    zone_type: {
      type: String,
      required: true,
      validator: isZone
    }
  },
  setup() {
    let gameState = useNamespacedState<GameStateData>('game',
      ['game_state', 'me', 'opponent']);
    let {game_state, me, opponent} = mapUnwrapStates(gameState);

    return {
      game_state,
      me,
      opponent
    }
  },
  computed: {
    prodiges(): ProdigeData[] {
      let list;
      if (this.zone_type == "opp_zone") {
        list = this.opponent.prodiges;
      } else {
        list = this.me.prodiges;
      }
      return list;
    },
    player(): PlayerData {
      let player: PlayerData;
      if (this.zone_type == "opp_zone") {
        player = this.opponent;
      } else {
        player = this.me;
      }
      return player;
    },
    interactiveProdige(): boolean {
      const correctZoneType = this.zone_type === 'player_zone'
      const myTurn = this.me.my_turn
      const playersPlayingProdigePhase = this.game_state.phase_name === 'PlayersPlayingProdiges'
      return correctZoneType && myTurn && playersPlayingProdigePhase
    }
  },
  methods: {
    play_prodige(prodige_name: string) {
      socket.game.playProdige(prodige_name);
    }
  },
  data() {
    return {}
  },
});
