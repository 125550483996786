
import {defineComponent} from "@vue/runtime-core";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import {useNamespacedState} from "vuex-composition-helpers";
import {GameStateData} from "@/stores/game";
import socket from "@/utils/socket";

export default defineComponent({
  name: "TargetGlyphe",
  props: {
    value: {
      required: true
    }
  },
  setup() {
    let {me} = mapUnwrapStates(useNamespacedState<GameStateData>("game", ["me"]))
    return {me}
  },
  methods: {
    removeGlyphe() {
      if (typeof this.value === 'string') {
        socket.game.removeGlypheVoieTarget(this.value);
      } else if (typeof this.value === 'number') {
        socket.game.removeGlypheTarget(this.value);
      }
    },
  },
  computed: {
    backgroundImage(): string {
      let img_url: string = 'url(' + require('../../assets/glyphes/Glyphe_' + this.glyphe_value + '.png') + ')';
      return img_url;
    },
    glyphe_value(): number {
      let return_value: number = 0
      if (typeof this.value === 'string') {
        return_value = this.me.voies[this.value]
      } else if (typeof this.value === 'number') {
        return_value = this.value
      }
      return return_value
    }
  }
})
