
import { defineComponent, ref } from "@vue/runtime-core";
import { mapUnwrapStates } from "@/types/vuex-composition-helpers_unwrap";
import { useNamespacedState } from "vuex-composition-helpers";
import { GameStateData } from "@/stores/game";
import Lottie from "lottie-web";

export default defineComponent({
    setup() {
        let { spectator_mode, me, opponent, winner, equality } = mapUnwrapStates(
            useNamespacedState<GameStateData>("game", [
                "spectator_mode",
                "me",
                "opponent",
                "winner",
                "equality"
            ])
        );

        let end_screen_vfx = ref<HTMLDivElement>();

        return {
            end_screen_vfx,
            spectator_mode,
            me,
            opponent,
            winner,
            equality
        };
    },
    watch: {
        hasResult: {
            immediate: true,
            handler: function () {
                if (
                    this.hasResult &&
                    !this.spectator_mode &&
                    this.end_screen_vfx
                ) {
                    this.end_screen_vfx.innerHTML = "";
                    setTimeout(() => {
                        if (!this.end_screen_vfx) return;
                        let vfx_type: string = this.won ? 'victory' : 'defeat';
                        vfx_type = this.equality ? 'equality' : vfx_type;
                        Lottie.loadAnimation({
                            animationData: require(`/src/assets/vfx/${vfx_type}_anim.json`),
                            container: this.end_screen_vfx,
                            autoplay: true,
                            loop: false,
                        });
                    }, 300);
                }
            },
        },
    },
    computed: {
        hasResult(): boolean {
            return !!this.winner;
        },
        won(): boolean {
            return this.me.pseudo === this.winner && !this.equality;
        },
        lost(): boolean {
            return this.opponent.pseudo === this.winner && !this.equality;
        },
    },
});
