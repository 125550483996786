
import SelectableOpp from './SelectableOpp.vue'
import SelectableGame from "@/components/login_components/SelectableGame.vue";
import {defineComponent} from "@vue/runtime-core";
import {useNamespacedState, useState} from "vuex-composition-helpers";
import {LobbyStateData} from "@/stores/lobby";
import {RootStateData} from "@/stores/global";
import socket from "@/utils/socket";
import {mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";

export default defineComponent({
  setup() {
    let {player_pseudo} = mapUnwrapStates(useState<RootStateData>(['player_pseudo']));
    let lobbyState = useNamespacedState<LobbyStateData>(
      'lobby',
      [
        'panel',
        'listOpp',
        'selected_opponent',
        'invitation',
        'listGamesToSpectate',
        'selected_game_to_spectate'
      ]
    );

    return {
      player_pseudo,
      lobbyState,
    }
  },
  data() {
    return {
      current_pseudo: '',
      pseudoTaken: false,
      pseudoTooShort: false,
      pseudoProblemText: '-'
    }
  },
  components: {
    SelectableOpp,
    SelectableGame
  },
  created() {
    this.$socket.$subscribe('pseudo_already_taken', () => {
      this.pseudoTaken = true;
      this.pseudoProblemText = 'Pseudo indisponible'
    });
  },
  computed: {
    pseudo_validated: function(): boolean {
      return this.player_pseudo !== ""
    },
    switch_btn_text: function(): string {
      let text: string = ''
      if (this.lobbyState.panel.value === 'select-opp') {
        text = "VOIR PARTIES"
      } else if (this.lobbyState.panel.value === 'spectate') {
        text = "ADVERSAIRES"
      }
      return text
    },
    opponentSelected: function(): boolean {
      return this.lobbyState.selected_opponent.value !== ''
    },
    gameSelected: function(): boolean {
      return this.lobbyState.selected_game_to_spectate.value !== ''
    }
  },
  methods: {
    sendPseudo() {
      this.pseudoTooShort = false;
      this.pseudoTaken = false;
      if (this.current_pseudo.length < 16 && this.current_pseudo.length > 2) {
        socket.login.sendPseudo(this.current_pseudo)
      } else {
        this.pseudoTooShort = true;
        this.pseudoProblemText = 'Pseudo invalide, au moins 3 caractères'
      }
    },
    switchPanelState() {
      this.$store.commit('lobby/switchPanelState');
    },
    validGameToSpectate() {
      socket.login.spectate(this.lobbyState.selected_game_to_spectate.value)
    },
    validOpp() {
      if (this.lobbyState.selected_opponent.value !== '') {
        this.$store.commit('lobby/setInvitationRequest', this.lobbyState.selected_opponent.value);
        socket.login.join(this.lobbyState.selected_opponent.value)
      }
    },
    acceptInvite() {
      socket.login.acceptMatch()
    },
    refuseInvite() {
      this.$store.commit('lobby/resetMatchOffer');
      socket.login.cancelMatch()
    },
    cancelInvite() {
      this.$store.commit('lobby/resetMatchOffer');
      socket.login.cancelMatch()
    }
  }
});
