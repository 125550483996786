
import SpectatorScreen from "@/components/shared/SpectatorScreen.vue";
import GameZone from './game_components/GameZone.vue'
import MenuBar from './menu_components/MenuBar.vue'
import Voie from './game_components/Voie.vue'
import {defineComponent} from "@vue/runtime-core";
import {useNamespacedGetters, useNamespacedState} from "vuex-composition-helpers";
import {GameStateData, GameStateGetters} from "@/stores/game";
import {mapUnwrapGetters, mapUnwrapStates} from "@/types/vuex-composition-helpers_unwrap";
import socket from "@/utils/socket";
import RoundedButton from "@/components/shared/RoundedButton.vue";
import RequiredAction from "@/components/game_components/RequiredAction.vue";
import MaitriseChoice from "@/components/game_components/MaitriseChoice.vue";
import EndGameScreen from "@/components/game_components/EndGameScreen.vue";
import DisplayedSection from "@/components/shared/DisplayedSection.vue";
import PauseScreen from "@/components/shared/PauseScreen.vue";
import ConfirmExitScreen from "@/components/shared/ConfirmExitScreen.vue";
import ExitScreen from "@/components/shared/ExitScreen.vue";


export default defineComponent({
    props: {
        roomId: String,
    },
    setup() {
        const {
            spectator_mode,
            roomId,
            me,
            opponent,
            game_state,
            required_actions,
            current_selection,
            paused,
            confirm_exit,
            opponent_left_game
        } = mapUnwrapStates(useNamespacedState<GameStateData>('game', [
            'spectator_mode',
            'roomId',
            'me',
            'opponent',
            "game_state",
            "required_actions",
            "current_selection",
            "paused",
            'confirm_exit',
            'opponent_left_game'
        ]))

        const {
            player_prodiges,
            opp_prodiges
        } = mapUnwrapGetters(useNamespacedGetters<GameStateGetters>('game', ['player_prodiges', 'opp_prodiges']));

        const voies = ['air', 'eau', 'terre', 'feu'];

        return {
            spectator_mode,
            room_id: roomId,
            me,
            opponent,
            voies,
            player_prodiges,
            opp_prodiges,
            game_state,
            required_actions,
            current_selection,
            paused,
            confirm_exit,
            opponent_left_game
        }
    },
    created() {
        if (this.room_id == '') {
            this.$router.push('/');
        }
    },
    watch: {
        me: {
            handler() {
                document.title = `${this.me.my_turn ? '⏰' : ''} Dojo - Game vs ${this.opponent.pseudo}`;
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        showValidButton(): boolean {
            let allVoiesPlayed = true;
            for (let voie in this.me.voies) {
                if (this.me.voies[voie] === -1) {
                    allVoiesPlayed = false;
                    break;
                }
            }
            const isNotSpectator = !this.spectator_mode;
            const isPlayingGlyphesPhase = this.game_state.phase_name === "PlayingGlyphes";
            // const isRequiredAction = ['RoundResolution', "PlayersApplyTalent"].includes(this.game_state.phase_name)
            //   && (this.required_actions.me?.target_number === this.current_selection.length || (!!this.required_actions.me && this.required_actions.me?.min_target_number <= this.current_selection.length));
            const isMyTurn = this.me.my_turn

            return isNotSpectator && isPlayingGlyphesPhase && allVoiesPlayed && isMyTurn;
        },
        validButtonLabel(): string {
            switch (this.game_state.phase_name) {
                case "PlayingGlyphes":
                    return "Valider vos glyphes joués";
                default:
                    return "Valider";
            }
        }
    },
    methods: {
        validateAction() {
            socket.game.validSelection();
        }
    },
    components: {
        ExitScreen,
        PauseScreen,
        DisplayedSection,
        SpectatorScreen,
        MaitriseChoice,
        RequiredAction,
        RoundedButton,
        GameZone,
        MenuBar,
        Voie,
        EndGameScreen,
        ConfirmExitScreen
    }
});
