import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56365356"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "first_token" }
const _hoisted_2 = { class: "pseudo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.zone_type, "pseudo-zone"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([{hidden: _ctx.tokenHidden}, "token"])
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.display_pseudo), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["hp", {
            'hp--down': _ctx.deltaHp < 0,
            'hp--up': _ctx.deltaHp > 0
        }])
    }, _toDisplayString(_ctx.hpValue), 3)
  ], 2))
}